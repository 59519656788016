import Header from "../../common/ui/Header";
import DepartmentsTab from "../../common/settings/DepartmentsTab";
import OrganizationTab from "../../common/settings/OrganizationTab";
import TitlesTab from "../../common/settings/TitlesTab";
import RolesTab from "../../common/settings/RolesTab";

import { useQuery } from "react-query";
import { useState } from "react";
import { useSelector } from "react-redux";

import getOrg from "../../queries/organization";
import getDepartments from "../../queries/org_departments";
import getRoles from "../../queries/roles";
import getTitles from "../../queries/titles";

const SettingsIndex = () => {
  const user = useSelector((state) => state.session.user);
  const [formActive, setFormActive] = useState({ organization: false, departments: false, titles: false, roles: false });
  const [activeTab, setActiveTab] = useState({ organization: "border-b-2 border-purple-500", departments: "border-b-0", titles: 'border-b-0', roles: "border-b-0" });
  const [currentTab, setCurrentTab] = useState({ organization: "visible", departments: "invisible", titles: "invisible", roles: "invisible" });

  const {data: organisation, refetch: refetchOrganisation} = useQuery(['get_organisation', {
    params: {token: user.token}, 
    payload: {}
  }], getOrg);

  const { data: departments, refetch: refetchDepartments } = useQuery(['get_departments', {
    params: {token: user.token}, 
    payload: {}
  }], getDepartments);

  const { data: roles, refetch: refetchRoles } = useQuery(['get_roles', {
    params: {token: user.token}, 
    payload: {}
  }], getRoles);

  const { data: titles, refetch: refetchTitles } = useQuery(['get_titles', {
    params: {token: user.token}, 
    payload: {}
  }], getTitles);

  const changeTab = (tab) => {
    let tabs = {
      organization: "border-b-0",
      departments: "border-b-0",
      titles: "border-b-0",
      roles: "border-b-0"
    };
    tabs[tab] = "border-b-2 border-purple-500";

    let visibility = {
      organization: "invisible",
      departments: "invisible",
      titles: "invisible",
      roles: "invisible"
    };
    visibility[tab] = "visible";

    setActiveTab(tabs);
    setCurrentTab(visibility);
  };

  const switchTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "departments":
        changeTab("departments");
        break;

      case "roles":
          changeTab("roles");
          break;

      case "titles":
        changeTab("titles");
        break;

      default:
        changeTab("organization");
        break;
    }
  };

  return (
    <div>
      <Header title="Settings" subtitle="Organisation Settings" />
      <div className="w-full h-auto relative bg-blue-50 mt-20">
        <div className="w-auto">
          <h2 className="w-auto pl-12 pt-4 pb-3 capitalize text-gray-400 border-b">
            Settings
          </h2>
        </div>
      </div>
      
      <div className="w-auto bg-white px-6 py-4">
        <nav className="flex flex-col sm:flex-row">
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.organization}
            onClick={e => switchTab(e, "organization")}
          >Organization</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.departments}
            onClick={e => switchTab(e, "departments")}
          >Departments</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.roles}
            onClick={e => switchTab(e, "roles")}
          >Roles</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.titles}
            onClick={e => switchTab(e, "titles")}
          >Titles</button>
        </nav>

        <div className="items-center justify-center h-auto">
          <div className="relative container">
            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.organization}>
              {organisation?.data === undefined ? null : <OrganizationTab organization={organisation.data}  refetchOrganisation={refetchOrganisation}/>}
            </div>

            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.departments}>
              {departments?.data?.content === undefined ? null : <DepartmentsTab departments={departments.data.content} refetchDepartments={refetchDepartments}/>}
            </div>

            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.roles}>
              {roles?.data?.content === undefined ? null : <RolesTab roles={roles.data.content}  refetchRoles={refetchRoles}/>}
            </div>

            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.titles}>
              {titles?.data?.content === undefined ? null : <TitlesTab titles={titles.data.content} refetchTitles={refetchTitles} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingsIndex;
