import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";
import { useAlert } from 'react-alert';
import { useSelector } from "react-redux";

import uploadContact from "../../mutations/AddContact";

const AddContact = ({ showAddContactClass, toggleAddContactForm }) => {
  const loading = false;
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const [contacts, setContacts] = useState([]);
  const className = `add-contact-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddContactClass}`;

  const re = /^0\d{9}$/;
  const testRegular = (phone) => {
    return re.exec(phone) !== null;
  };

  const doUploadContact = useMutation(uploadContact, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      alert.success("Contact Added!");
      toggleAddContactForm(null);
    },
  });

  // const removeContact = (e, phone) => {
  //   e.preventDefault();
  //   alert.error(`Are you sure you want to remove ${phone}?`, {
  //     timeout: 15000,
  //     action: () => {
  //       const similar = contacts.filter(contact => contact.phone_number === phone);
  //       if (similar.length > 0) {
  //         const exclude = contacts.filter(contact => contact.phone_number !== phone);
  //         setContacts(exclude);
  //       }
  //     }
  //   })
  // };

  // const addContact = (e, values) => {
  //   e.preventDefault();
  //   if (values.call_center === "") alert.error("Please select a call center!");
  //   const exclude = contacts.filter(contact => contact.phone_number !== values.phone);
  //   exclude.push({ phone_number: values.phone, call_center_id: values.call_center });
  //   setContacts(exclude);
  // };

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Add Contact
        </h1>
        <button 
          type="button" 
          onClick={toggleAddContactForm}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <Formik
          initialValues={{
            name: "",
            phone: "",
            company: "",
            address: ""
          }}

          onSubmit={({ name, phone, company, address }, { resetForm }) => {
            if (phone.length < 10){
              alert.error("Enter valid phone number");
            }
            doUploadContact.mutate({
              payload: {
                name: name,
                phone: testRegular(phone) ? `254${phone}` : phone,
                company: company,
                address: address,
              },
              params: {token: user.token}
            });
            resetForm({
              name: "",
              phone: "",
              company: "",
              address: "",
            });
          }}
        >
          {
            formik => (
              <Form >
                <Field
                  className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="name"
                  placeholder="Enter their full name"
                  disabled={loading}
                  required
                />
                <div className="flex">
                  <Field
                    className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                    as="input"
                    name="phone"
                    placeholder="Enter their phone number eg. (254../07..)"
                    disabled={loading}
                    required
                  />
                  {/* <button
                    disabled={loading}
                    className="w-auto flex-none button flex-auto bg-blue-500 rounded p-1 text-white hover:bg-blue-800 mx-2 my-5"
                    type="button"
                    onClick={e => addContact(e, formik.values)}
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                  </button>
                  <button
                    disabled={loading}
                    className="w-auto flex-none button flex-auto bg-green-500 rounded p-1 text-white hover:bg-green-800 mr-1 my-5"
                    type="button"
                  >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
                    </svg>
                  </button> */}
                </div>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="company"
                  placeholder="Enter their company name"
                  disabled={loading}
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="address"
                  placeholder="Enter their address"
                  disabled={loading}
                />
                {/* <div className="w-full flex">
                  {contacts === undefined ? null : (contacts.map((contact) => {
                    return <div 
                      key={contact.phone_number}
                      className="w-auto flex-none bg-green-100 rounded p-2 m-2 "
                    >
                      {contact.phone_number}
                      <button
                        onClick={e => removeContact(e, contact.phone_number)}
                        className="shadow inline items-center float-right justify-center text-gray-400 m-1 ml-2 hover:text-gray-500 hover:bg-gray-100"
                      >
                        <svg className="h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>
                    </div>;
                  }))}
                </div> */}
                <button
                  disabled={loading}
                  className="button block bg-blue-500 rounded p-1 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Add Contacts</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default AddContact;
