import { gql } from '@apollo/client';

const TOPUPS_SUM = gql`
query GetTotalTopups($call_center_id: uuid!) {
  call_center_by_pk(id: $call_center_id) {
    chart_of_account {
      transactions_aggregate {
        aggregate {
          sum {
            amount
          }
        }
      }
    }
  }
}
`;

export default TOPUPS_SUM;