import React, { useEffect, useMemo } from "react";
// import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';

import getCallCost from '../../queries/call_cost';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CallCostChart = ({ days }) => {
  const user = useSelector((state) => state.session.user);
  const from = moment().subtract(days, 'days').local().format();
  const to = moment().local().format();

  const { data, refetch: fetchCalls } = useQuery(['get_call_cost', {
    params: {from: from, to: to, token: user.token}, 
    payload: {}
  }], getCallCost, {
    enabled: false
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const callData = useMemo(
    () => ({
      labels: data?.data?.callByAgents.map(e => e.agent),
      datasets: [
        {
          label: "# of calls",
          data: data?.data?.callByAgents.map(e => e.total),
          backgroundColor: 'rgba(53, 162, 235, 0.8)',
        },
      ],
    }),
    [data]
  );

  useEffect(() => {
    fetchCalls();
  }, []);

  return (
    <div className="h-11/12 w-11/12 mx-auto py-2">
      <Bar
        className="w-full"
        options={options}
        data={callData}
      />
    </div>
  );
};
export default CallCostChart;
