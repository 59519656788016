import { gql } from "@apollo/client";

const FORGOT_PASSWORD = gql`
mutation ForgotPassword($email: EmailInput!) {
  forgotPassword(payload: $email){
    statusCode
    message
  }
}
`;

export default FORGOT_PASSWORD