import { formatDistanceToNow, parseISO } from "date-fns";
import { memo } from "react";

const NotesTable = ({ notes }) => {

    return (
        <div className="w-full">
            <div className="w-full text-xl text-gray-500 m-2 my-4 border-b-2 border-bg-grey">
                Recent Notes
            </div>
            <table className="w-full table-fixed divide-y divide-gray-200 bg-white shadow-xl">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-1 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-left">
                            <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                            </svg>
                        </th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Caller</th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-8/12 text-left">Note</th>
                        <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Time</th>
                    </tr>
                </thead>
                <tbody>
                    {notes.map((note) => (
                        <tr key={note.id} className="hover:bg-blue-100 cursor-pointer">
                            <td className="px-6 py-3 text-left">
                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </td>
                            <td className="px-6 py-3 text-left">{note.call_log.caller}</td>
                            <td className="px-6 py-3 text-left">{note.note}</td>
                            <td className="px-6 py-3 text-left">{formatDistanceToNow(parseISO(note.createdDate), { addSuffix: false })}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default memo(NotesTable);