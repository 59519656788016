import { gql } from "@apollo/client";

const AddTeam = gql`
mutation CreateTeam($payload: TeamInput!) {
  createTeam(payload: $payload){
    statusCode
    message
  }
}
`;

export default AddTeam;