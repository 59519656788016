import React from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { update, logout } from "../../../store/reducers/session";
import { withRouter } from "react-router";
import { useAlert } from 'react-alert';
import { useSelector } from "react-redux";
import {
  UserCircleIcon,
  ChevronRightIcon,
  PhoneIcon,
  LogoutIcon,
  ChartSquareBarIcon,
  CurrencyDollarIcon,
  SpeakerphoneIcon,
  CogIcon,
  MenuAlt1Icon,
  ChevronLeftIcon,
  UserIcon,
  UserGroupIcon,
  HomeIcon,
  DeviceMobileIcon
} from "@heroicons/react/outline";

import logo from "../../../static/logo-w.png";

const Some = ({ history, isOpen, setIsOpen }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const updateDispatch = useDispatch();
  // const teamChosenClass = user.user.callCenterId === undefined || user.user.callCenterId === "" ? "block" : "block";
  const teamChosenClass = "block";
  const navClass = isOpen ? "block" : "hidden";

  // const resetHome = (e) => {
  //   e.preventDefault();
  //   updateDispatch(update({ party: { ...user.party, callCenterId: "" } }));
  //   history.push("/p/teams");
  // };

  const signOut = (e) => {
    e.preventDefault();
    alert.error('Are you sure you want to logout?', {
      timeout: 15000,
      action: () => {
        updateDispatch(logout());
        history.push("/auth/login");
      }
    });
  };

  return (
    <nav className={"4/12 lg:w-1/4 2xl:w-2/12 fixed top-0 bottom-0 left-0 flex flex-col pt-6 pb-8 bg-gray-800 z-50 " + navClass} >
      <div className="flex w-full items-center px-6 pb-6 mb-6 lg:border-b border-gray-700">
        <Link className="flex-auto text-xl text-white font-semibold" to="/">
          <img className="h-8" src={logo} alt="" width="auto" />
        </Link>
        <button
          onClick={(e) => {
            e.preventDefault();
            setIsOpen(!isOpen);
          }}
          className="p-2 ml-2 text-gray-400 focus:outline-none visible xl:invisible">
          <ChevronLeftIcon className="h-6 w-6 text-sm text-white" />
        </button>
      </div>
      <div className="px-4 py-4">
        <h3 className={"mb-2 text-xs uppercase text-gray-500 font-medium " + teamChosenClass}>
          Main
        </h3>
        <ul className="mb-8 text-sm font-medium">
          {/* <li>
            <NavItem 
              onClick={e => resetHome(e)}
              href="/p/teams"
              teamChosenClass={"block"}
            >
              <span className="inline-block mr-3">
                <HomeIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Home</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li> */}
          <li>
            <NavItem 
              href="/p/dashboard"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <ChartSquareBarIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Dashboard</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
          <li>
            <NavItem 
              href="/p/calls"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <PhoneIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Calls</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
          {/* <li>
            <NavItem 
              href="/p/queue"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <MenuAlt1Icon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Queue</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li> */}
          <li>
            <NavItem 
              href="/p/contacts"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <UserCircleIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Contacts</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
          <li>
            <NavItem 
              href="/p/phones"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <DeviceMobileIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Phone numbers</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
        </ul>
        <h3 className={"mb-2 text-xs uppercase text-gray-500 font-medium " + teamChosenClass}>
          Management
        </h3>
        <ul className="mb-8 text-sm font-medium">
          <li>
            <NavItem 
              href="/p/ivr"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <SpeakerphoneIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>IVR</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>          
          {/* <li>
            <NavItem 
              href="/p/transactions"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-3">
                <CurrencyDollarIcon className="text-blue-100 w-5 h-5" />
              </span>
              <span>Transactions</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li> */}
        </ul>
        <h3 className="mb-2 text-xs uppercase text-gray-500 font-medium">
          Account
        </h3>
        <ul className="mb-8 text-sm font-medium">
          {/* <li>
            <NavItem
              className="flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded"
              href="/p/profile"
              teamChosenClass={"block"}
            >
              <span className="inline-block mr-4">
                <UserIcon className="text-white w-5 h-5" />
              </span>
              <span>Profile</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li> */}
          <li>
            <NavItem
              className="flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded"
              href="/p/members"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-4">
                <UserGroupIcon className="text-white w-5 h-5" />
              </span>
              <span>Members</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
          <li>
            <NavItem
              className="flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded"
              href="/p/settings"
              teamChosenClass={teamChosenClass}
            >
              <span className="inline-block mr-4">
                <CogIcon className="text-white w-5 h-5" />
              </span>
              <span>Settings</span>
              <span className="inline-block ml-auto">
                <ChevronRightIcon className="text-gray-400 w-3 h-3" />
              </span>
            </NavItem>
          </li>
          <li>
            <NavItem
              className="flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded"
              onClick={(e) => signOut(e)}
              href="/p/logout"
              teamChosenClass={"block"}
            >
              <span className="inline-block mr-4">
                <LogoutIcon className="text-gray-600 w-5 h-5" />
              </span>
              <span>Log Out</span>
            </NavItem>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const NavItem = ({ children, href, onClick, teamChosenClass }) => {
  return (
    <NavLink
      to={href}
      onClick={onClick}
      className={"flex items-center pl-3 py-3 pr-2 text-gray-50 hover:bg-gray-900 rounded " + teamChosenClass}
      activeClassName="text-gray-50 bg-blue-500"
    >
      {children}
    </NavLink>
  );
};

export default withRouter(Some);
