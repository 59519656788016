import { BASE_URL, API_VERSION, AUTH_TOKEN, AT_PORT } from '../utils/config';
import axios from 'axios';

function addIVR({params, payload}){
  let url = BASE_URL + AT_PORT + API_VERSION + '/ivrOptions';
  if (params?.method === "patch" && params?.publicId !== null) url += `/${params.publicId}`;
  
  let config = {
    method: params.method,
    url: url, 
    headers: {
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${params.token}`
    },
    data: payload,
  };

  if (params?.publicId === undefined) 
    config = {
      method: "post",
      url: BASE_URL + AT_PORT + API_VERSION + '/ivrOptions', 
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${params.token}`
      },
      data: payload,
    };

  return new Promise((resolve, reject) => {
    axios(config)
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default addIVR;