// import { useState } from "react";
import { XIcon, InformationCircleIcon, CheckIcon, ExclamationIcon } from "@heroicons/react/outline";

const AlertTemplate = ({ style, options, message, close }) => {
  const showOkButton = options.action !== undefined ? "block" : "hidden";
  const callback = () => {
    if (options.action !== undefined) {
      const todo = options.action;
      todo();
    }
    close();
  };

  return (
    <div className="flex rounded bg-gray-100 shadow-lg" style={style}>
      <button 
        className="flex-auto bg-grey-800 rounded-sm shadow-sm px-2 mr-2"
      >
        {options.type === 'info' && <InformationCircleIcon className="w-5 h-5 mt-1"></InformationCircleIcon>}
        {options.type === 'success' && <CheckIcon className="w-5 h-5 mt-1"></CheckIcon>}
        {options.type === 'error' && <ExclamationIcon className="w-5 h-5 mt-1"></ExclamationIcon>}
      </button>
      <p className="flex-auto text-lg text-black px-2 py-5">
        {message}
      </p>
      <button 
        className={"h-8 flex-auto bg-blue-600 text-sm text-white rounded-sm shadow-sm mr-2 mt-5 px-3 py-1 hover:bg-blue-800 " + showOkButton}
        onClick={callback}
      >
        Ok
      </button>
      <button 
        className="flex-auto bg-grey-800 rounded-sm shadow-sm px-2 ml-2"
        onClick={close}
      >
        <XIcon className="w-5 h-5"></XIcon>
      </button>
    </div>
  );
};

export default AlertTemplate;