import { withRouter } from 'react-router-dom'
import { useState } from 'react';
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';
// import moment from "moment";
import Header from "../../common/ui/Header";
import getQueue from '../../queries/call_queue';

const QueueIndex = ({ history }) => {
  const user = useSelector((state) => state.session.user);

  // useSubscription(CALL_QUEUE, {
  //   variables:{
  //     call_center_id: user.party.callCenterId
  //   },
  //   onSubscriptionData: ({ subscriptionData }) => {
  //     let call_queue = subscriptionData.data.call_queue;
  //     call_queue = call_queue.map((call) => {
  //       // console.log(moment.unix(call.create_time/1000000).utc());
  //       const wait_time = moment.unix(call.create_time/1000000).utc().fromNow();
  //       return { ...call, wait_time: wait_time };
  //     });
  //     setCalls(call_queue);
  //     console.log(call_queue);
  //   }
  // });

  const { data: calls } = useQuery(['get_queue', {
    params: {org: user.org_id}, 
    payload: {}
  }], getQueue);

  return (
    <div className="relative h-full">
      <Header title="Queues" subtitle="Call Queues" />
      <div className="bg-blue-50 flex flex-row mt-20">
        <div className="flex-none w-1/5">
          <h2 className="pl-5 pt-2 pb-5 mt-4 capitalize text-2xl text-gray-400 border-b">
            Call List
          </h2>
        </div>
      </div>
      <div className="w-full">
        <div className="w-auto inline-block text-xl text-black-500 ml-20 mt-5 mb-4">
          <strong>{calls.length}</strong> calls In the queue.
        </div>
        {calls === undefined ? null : <Queue history={history} calls={calls} />}
      </div>
    </div>
  );
};

const Queue = ({ history, calls }) => {
  return (
    <div className="w-full p-4">
      <table className="w-11/12 mx-auto table divide-y divide-gray-200 bg-white shadow-xl">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-left"></th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-center">Caller</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-center">Destination</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Wait time</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Priority</th> */}
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-center">Refer</th> */}
          </tr>
        </thead>
        <tbody>
          {calls.map((call) => (
            <tr key={call.id} className="hover:bg-blue-100 cursor-pointer">
              <td className="px-6 py-3">
                <span>
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-3 text-center">{call.caller_number}</td>
              <td className="px-4 py-3 text-center">{call.callee_number}</td>
              <td className="px-4 py-3 text-center">{String(call.wait_time) === "NaN" ? "0:00" : call.wait_time}</td>
              {/* <td className="px-4 py-3">{call.department}</td> */}
              {/* <td className="px-4 py-3 text-center">
                <button
                  onClick={(e) => { e.preventDefault(); history.push(`/p/calls/outgoing/${call.caller_name}`) }}
                  disabled={true}
                  className="w-auto h-10 bg-green-300 rounded-md px-3 py-1 m-1">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withRouter(QueueIndex);
