import { gql } from "@apollo/client";
const CONTACTS = gql`
query Contacts($call_center_id: uuid!, $phone_number: String!) {
  contact(where: { call_center_id: { _eq: $call_center_id }, phone_number: { _regex: $phone_number } }) {
    id
    phone_number
    party {
      id
      name
    }
  }
}
`;

export default CONTACTS;
