import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import uploadNote from "../../mutations/AddNote";

const NewNote = ({metaData, refetchCall}) => {
  const loading = false;
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);

  const doAddNote = useMutation(uploadNote, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      alert.success("Note Added!");
      refetchCall();
    },
  });

  return (
    <div className="w-full">
      <Formik
        initialValues={{
          note: "",
        }}
        onSubmit={({ note }, { resetForm }) => {
          doAddNote.mutate({
            payload: {
              tenant: user.user.institution,
              note: note,
              call: {
                id: metaData.callId,
                tenant: user.user.institution,
                version: 1
              },
              agent: {
                id: 44,
                version: 0,
                tenant: user.user.institution
              }
            },
            params: {token: user.token}
          });
          resetForm({
            note: "",
          });
        }}
      >
        <Form>
          <Field
            className="block bg-white w-full h-24 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4"
            as="textarea"
            name="note"
            placeholder="Add a new Note"
            disabled={loading}
          />
          <button
            disabled={loading}
            className="button bg-green-400 rounded px-4 py-1 float-right text-white hover:bg-green-800 mt-1"
            type="submit"
          >
            Add Note
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default NewNote;
