import { Formik, Field, Form } from "formik";
import { useState } from "react";
import { ViewGridAddIcon } from "@heroicons/react/outline";


const Phone = ({ loading, startCall }) => {
  const [showMakeCall, setShowMakeCall] = useState(false);

  const toggleMakeCall = (e) => {
    if (e !== null) e.preventDefault();
    if (showMakeCall)
      setShowMakeCall(false);
    else
      setShowMakeCall(true);
  }

  const makeCallClass = showMakeCall ? "visible" : "invisible";

  return (
    <div className="absolute w-full h-auto right-0 bottom-0">
      <div
        onClick={e => toggleMakeCall(e)}
        className="absolute right-6 bottom-28 p-6 rounded-full bg-green-500 text-white z-50 cursor-pointer">
        <ViewGridAddIcon className="h-8 w-8" />
      </div>
      <div className={"w-5/6 md:w-3/6 lg:w-2/6 h-60 absolute bottom-28 right-7 md:right-32 bg-white rounded-lg shadow-lg z-50 " + makeCallClass}>
        <button
          type="button"
          onClick={e => toggleMakeCall(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="mt-8 text-center text-lg">Make Call</h2>
        <Formik
          initialValues={{
            phone: ""
          }}
          onSubmit={({ phone }) => {
            setShowMakeCall(false);
            startCall(phone);
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="bg-white w-11/12 h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0 mt-8 ml-4 pl-4"
                  as="input"
                  name="phone"
                  placeholder="Enter their phone number"
                  disabled={loading}
                  required
                />
                <button
                  disabled={loading}
                  className="w-24 button block bg-blue-500 rounded-full p-1 px-2 text-white hover:bg-blue-800 m-auto mt-8 p-2"
                  type="submit"
                >
                  <svg className="w-6 h-6 ml-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                  </svg>
                </button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default Phone;