import Header from "../../common/ui/Header";
import DetailsTab from "../../common/profile/DetailsTab";
import PermissionsTab from "../../common/profile/PermissionsTab";
import RolesTab from "../../common/profile/RolesTab";

import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import getProfile from "../../queries/profile";

const Profile = () => {
  const user = useSelector((state) => state.session.user);
  const [formActive, setFormActive] = useState({ profile: false, departments: false, permissionss: false, roles: false });
  const [activeTab, setActiveTab] = useState({ profile: "border-b-2 border-purple-500", departments: "border-b-0", permissionss: 'border-b-0', roles: "border-b-0" });
  const [currentTab, setCurrentTab] = useState({ profile: "visible", departments: "invisible", permissionss: "invisible", roles: "invisible" });
  const [roles, setRoles] = useState();
  const [permissions, setPermissions] = useState();

  const changeTab = (tab) => {
    let tabs = {
      profile: "border-b-0",
      departments: "border-b-0",
      permissionss: "border-b-0",
      roles: "border-b-0"
    };
    tabs[tab] = "border-b-2 border-purple-500";

    let visibility = {
      profile: "invisible",
      departments: "invisible",
      permissionss: "invisible",
      roles: "invisible"
    };
    visibility[tab] = "visible";

    setActiveTab(tabs);
    setCurrentTab(visibility);
  };

  const switchTab = (e, tab) => {
    e.preventDefault();
    switch (tab) {
      case "roles":
        changeTab("roles");
        break;

      case "departments":
        changeTab("departments");
        break;

      case "permissionss":
        changeTab("permissionss");
        break;

      default:
        changeTab("profile");
        break;
    }
  };

  return (
    <div>
      <Header title="Profile" subtitle="User Profile" />
      <div className="w-full h-auto relative bg-blue-50 mt-20">
        <div className="w-auto">
          <h2 className="w-auto pl-12 pt-4 pb-3 capitalize text-gray-400 border-b">
            Profile
          </h2>
        </div>
      </div>
      <div className="w-auto bg-white px-6 py-4">
        <nav className="flex flex-col sm:flex-row">
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.profile}
            onClick={e => switchTab(e, "profile")}
          >Details</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.roles}
            onClick={e => switchTab(e, "roles")}
          >Roles</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.permissionss}
            onClick={e => switchTab(e, "permissionss")}
          >Permissions</button>
        </nav>

        <div className="items-center justify-center h-auto">
          <div className="relative container">
            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.profile}>
              {!!user?.user ? <DetailsTab member={user} formActive={formActive} setFormActive={setFormActive} /> : null}
            </div>

            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.permissionss}>
              {!!user?.user?.authorities ? null : <PermissionsTab permissions={user.user.authorities} formActive={formActive} setFormActive={setFormActive} />}
            </div>

            <div className={"absolute top-5 inset-x-0 flex flex-col rounded bg-gray-100 justify-center p-3 mb-5 " + currentTab.roles}>
              {!!user.user.roles ? null : <RolesTab roles={roles} formActive={formActive} setFormActive={setFormActive} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;
