import { gql } from "@apollo/client";

const CALL_COST_SUM = gql`
query CallCostSum($call_center_id: uuid!) {
  call_log_cost_aggregate(where: {call_centre_id: {_eq: $call_center_id}}) {
    aggregate {
      sum {
        charge
      }
    }
  }
}
`;

export default CALL_COST_SUM;