import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
  name: 'sip_session',
  initialState: {
    online_status: {
      online: true
    }
  },
  reducers: {
    update: (state, action) => {
      state.online_status = action.payload;
    },
    remove: (state) => {
      state.online_status = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, remove } = sessionSlice.actions

export default sessionSlice.reducer;