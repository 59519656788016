import { createSlice } from '@reduxjs/toolkit'

const token = localStorage.getItem('token') ? localStorage.getItem('token') : "";
const adminToken = localStorage.getItem('adminToken') ? localStorage.getItem('adminToken') : "";
const user = localStorage.getItem('user') ? localStorage.getItem('user') : JSON.stringify({});

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    user: {
        token: token,
        adminToken: adminToken,
        user: user ? JSON.parse(user) : null
    },
  },
  reducers: {
    update: (state, action) => {
      if (action.payload.token) {
        state.user.token = action.payload.token;
        localStorage.setItem('token', action.payload.token);
      }
      if (action.payload.adminToken) {
        state.user.adminToken = action.payload.adminToken;
        localStorage.setItem('adminToken', action.payload.adminToken);
      }
      if (action.payload.user) {
        state.user.user = action.payload.user;
        localStorage.setItem('user', JSON.stringify(action.payload.user));
      }
    },
    logout: (state) => {
      state.user = {
        token: "",
        adminToken: "",
        user: {}
      };      
      localStorage.removeItem('token');
      localStorage.removeItem('adminToken');
      localStorage.removeItem('user');
    },
  },
});

// Action creators are generated for each case reducer function
export const { update, logout } = sessionSlice.actions

export default sessionSlice.reducer;