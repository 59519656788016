import { BASE_URL, API_VERSION, AT_PORT } from '../utils/config';
import axios from 'axios';

function exportCalls({ params, payload }){
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: BASE_URL + AT_PORT + API_VERSION + `/reports/csv?startDate=${payload.startDate}&endDate=${payload.endDate}&date`, 
      headers: {
        'Authorization': `Bearer ${params.token}`
      },
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default exportCalls;
