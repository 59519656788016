import { TrendingDownIcon, TrendingUpIcon } from "@heroicons/react/outline";
const Stat = ({ color, icon, name, value, trend, trendValue }) => {
  const StatIcon = icon;
  return (
    <div className={`flex-auto p-5 rounded bg-white shadow-lg hover:bg-${color}-100`}>
      <div className="flex flex-row gap-3 items-center">
        <div>
          <div className={`rounded-full p-2 bg-${color}-100 shadow-lg`}>
            <StatIcon className={`h-5 w-5 text-${color}-300`} />
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-3 items-center">
            <div className="">
              <h1 className="text-2xl font-bold text-gray-800">{value}</h1>
            </div>
            <div className="hidden">
              <div
                className={`flex flex-row gap-1 rounded-full py-1 px-3 text-xs ${
                  trend === "up" ? "bg-green-200" : "bg-red-200"
                }`}
              >
                <div>
                  {trend === "up" ? (
                    <TrendingUpIcon className="h-3 w-3" />
                  ) : (
                    <TrendingDownIcon className="h-3 w-3" />
                  )}
                </div>
                <div>{trendValue}</div>
              </div>
            </div>
          </div>
          <div className="text-gray-400 text-xs leading-3">{name}</div>
        </div>
      </div>
    </div>
  );
};

export default Stat;
