import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import {
  ClockIcon,
  CurrencyDollarIcon,
  PhoneIcon,
} from "@heroicons/react/outline";
import Header from "../../common/ui/Header";
import Stat from "../../common/ui/Stat";
import CallsTable from "../../common/dashboard/CallsTable";
import NotesTable from "../../common/dashboard/NotesTable";
import CallChart from "../../common/dashboard/CallChart";
import CallCostChart from "../../common/dashboard/CallCostChart";
import getAnalytics from "../../queries/analytics";
import getCallLogs from "../../queries/call";
import getNotes from "../../queries/notes";

const DashboardIndex = () => {
  const user = useSelector((state) => state.session.user);
  const [days, setDays] = useState(7);
  // const [logs, setLogs] = useState([]);

  const re = /^(?:\+?254)?(\d{9,12})$/;
  const testRegular = (phone) => {
    return re.exec(phone) !== null;
  };

  const { data: usageData, refetch: fetchAnalytics } = useQuery(['get_analytics', {
    params: {token: user.token}, 
    payload: {}
  }], getAnalytics, {
    enabled: false
  });

  const { data: logs, refetch: fetchLogs } = useQuery(['get_call_logs', {
    params: {token: user.token}, 
    payload: {}
  }], () => getCallLogs(), {
    enabled: false
  });

  const { data: notes, refetch: fetchNotes } = useQuery(['get_notes', {
    params: {token: user.token}, 
    payload: {}
  }], getNotes, {
    enabled: false
  });

  useEffect(() => {
    fetchAnalytics();
    fetchLogs();
    fetchNotes();
  }, []);

  return (
    <div>
      <Header title="Dashboard" subtitle="Your Insights" />
      <div className="w-full bg-blue-50 mt-20 pt-2">
        <div className="w-full min-h-screen">
          <div className="px-8 py-4">
            <div className="w-full">
              <div className="w-full text-xl text-gray-500 m-2 mt-0 mb-4 border-b-2 border-bg-grey">
                Usage Statistics
              </div>
              <div className="flex flex-row flex-wrap gap-3 mb-8">
                <Stat
                  icon={PhoneIcon}
                  value={usageData?.data?.callCountByDirection === undefined ? 0 : usageData.data.callCountByDirection.reduce((t, c) => t + c.total, 0)}
                  trend="down"
                  color="indigo"
                  name="Number of Calls"
                  trendValue="8%"
                />
                <Stat
                  icon={ClockIcon}
                  value={usageData?.data?.callCountByDirection === undefined ? 0 : usageData.data.callCountByDirection[0].total}
                  trend="up"
                  color="blue"
                  name="Outbound Calls"
                  trendValue="56%"
                />
                <Stat
                  icon={CurrencyDollarIcon}
                  value={usageData?.data?.callCountByDirection === undefined ? 0 : usageData.data.callCountByDirection[1].total}
                  color="yellow"
                  name="Inbound Calls"
                  trend="down"
                  trendValue="34%"
                />
                <Stat
                  icon={CurrencyDollarIcon}
                  value={0.0}
                  trend="down"
                  color="blue"
                  name="Top-Ups (Ksh.)"
                  trendValue="17%"
                />
              </div>
            </div>
            <div className="w-full my-4">
              <div className="w-full my-4">
                { logs?.data?.content === undefined ? null : <CallsTable logs={logs.data.content.splice(0, 5)} /> }
              </div>
              <div className="w-full">
                <div className="chart-header bg-white rounded-t flex">
                  <div className="w-4/5 flex-auto text-xl text-gray-500 m-2 my-4">
                    Call Traffic
                  </div>
                  <div className="w-auto flex-none text-lg text-gray-500 mt-3 mr-5">
                    <select
                      className="w-28 float-right rounded p-2 py-1"
                      onChange={e => { e.preventDefault(); setDays(e.target.value) }}
                    >
                      <option value={7}>Last week</option>
                      <option value={30}>This month</option>
                      <option value={90}>Last 3 months</option>
                      <option value={180}>Last 6 months</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex">
                  <div className="w-4/5 h-auto flex-none border-2 border-white mt-0.5 pb-4">
                    <CallChart days={days} />
                  </div>
                  <div className="w-1/5 flex-none bg-white border-t-2 border-gray-100 divide-y-2 divide-gray-100">
                    <div className="w-full pl-1 py-3">
                      <p className="text-xl text-black-500 m-2">Ringing</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.data?.callCountBySessionState === undefined ? 0 : usageData?.data?.callCountBySessionState[0].total}</p>
                    </div>
                    <div className="w-full pl-1 py-3">
                      <p className="text-xl text-black-500 m-2">Dropped</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.data?.callCountBySessionState === undefined ? 0 : usageData?.data?.callCountBySessionState[1].total}</p>
                    </div>
                    <div className="w-full pl-1 py-2">
                      <p className="text-xl text-black-500 m-2">Completed</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.data?.callCountBySessionState === undefined ? 0 : usageData?.data?.callCountBySessionState[2].total}</p>
                    </div>
                    <div className="w-full pl-1 py-3">
                      <p className="text-xl text-black-400 m-2">Active</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.data?.callCountBySessionState === undefined ? 0 : usageData?.data?.callCountBySessionState[0].total}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full my-4">
              <div className="w-full my-4">
              { notes?.data?.content === undefined ? null : <NotesTable notes={notes.data.content.splice(0, 5)} /> }
              </div>
              <div className="w-full my-8">
                <div className="chart-header bg-white rounded-t flex">
                  <div className="w-4/5 flex-auto text-xl text-gray-500 m-2 my-4">
                    Calls per Agent
                  </div>
                  <div className="w-auto flex-none text-lg text-gray-500 mt-3 mr-3">
                    <select className="w-28 float-right rounded p-2 py-1"
                    onChange={e => { e.preventDefault(); setDays(e.target.value) }}>
                      <option value={7}>Last week</option>
                      <option value={30}>This month</option>
                      <option value={90}>Last 3 months</option>
                      <option value={180}>Last 6 months</option>
                    </select>
                  </div>
                </div>
                <div className="w-full flex">
                  <div className="w-full flex-none border-2 border-white mt-0.5 pb-4">
                    <CallCostChart days={days}/>
                  </div>
                  {/* <div className="w-1/5 flex-none bg-white border-t-2 border-gray-100 divide-y-2 divide-gray-100">
                    <div className="w-full pl-1 py-3">
                      <p className="text-xl text-black-500 m-2">Inbound</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.inbound_count === undefined ? 0 : usageData.inbound_count}</p>
                    </div>
                    <div className="w-full pl-1 py-3">
                      <p className="text-xl text-black-500 m-2">Outbound</p>
                      <p className="text-lg text-gray-400 ml-2">{usageData?.outbound_count === undefined ? 0 : usageData.outbound_count}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardIndex;
