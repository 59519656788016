import { BASE_URL, AT_PORT, API_VERSION } from '../utils/config';
import axios from 'axios';

function getCallCost({ queryKey }){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: BASE_URL + AT_PORT + API_VERSION + '/calls/analysis/groupedTotals', 
      headers: {
        'Authorization': `Bearer ${body.params.token}`
      },
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default getCallCost;
