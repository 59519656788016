import { formatDistanceToNow, parseISO } from "date-fns";
import moment from "moment";

import NewNote from './NewNote'

const NotesIndex = ({ notes, metaData, refetchCall }) => {
  return (
    <div className="mt-3">
      <h6 className="font-semibold text-xl text-gray-500">Call Notes</h6>
      <div className="mt-3">
        {notes.map((n) => (
          <Note key={n.id} note={n} />
        ))}
      </div>
      <div className="flex flex-row gap-2">
        <span className="w-14 h-14 flex-none rounded-full bg-green-400 text-center text-white mt-4 mr-2">
          <svg className="w-6 h-6 mt-4 ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
        </span>
        <div className="flex-auto">
          <NewNote metaData={metaData} refetchCall={refetchCall}/>
        </div>
      </div>
    </div>
  );
};

const Note = ({ note }) => {
  return (
    <div className="w-auto max-w-lg pb-5 pt-2 border-t">
      {/* <div className="font-bold text-gray-500">{note.agent.name}</div> */}
      <div>{note.note}</div>
      <div className="text-gray-400">{formatDistanceToNow(parseISO(moment(note.createdDate).format()))}</div>
    </div>
  );
};

export default NotesIndex;
