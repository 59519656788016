import axios from '../utils/axios';

function addTransaction({queryKey}){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios
      .post('/transactions/add', body.payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default addTransaction;