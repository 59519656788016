import { useState } from "react";
import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import addTitle from "../../mutations/AddTitle";

const AddTitle = ({ showAddTitleClass, toggleAddTitleForm }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const loading = false;
  const className = `add-dep-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddTitleClass}`;

  const doAddTitle = useMutation(addTitle, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      alert.success("Title added successfully!");
      toggleAddTitleForm(null);
    },
  });

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Edit Title
        </h1>
        <button
          type="button"
          onClick={e => toggleAddTitleForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 my-5 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <Formik
          initialValues={{
            name: "",
          }}
          onSubmit={({ name }, { resetForm }) => {
            doAddTitle.mutate({
              payload: {
                "name": name,
              }, 
              params: {
                token: user.token
              }
            });
            resetForm({
              name: "",
            });
          }}
        >
          {
            formik => (
              <Form >
                <div className="w-11/12 m-auto py-2">
                  <h3 className="text-sm font-medium text-gray-500 border-b my-2">Title Details</h3>
                  <Field
                    className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                    as="input"
                    name="name"
                    placeholder="Enter the title name"
                    required
                    disabled={loading}
                  />
                  <button
                    disabled={loading}
                    className="w-auto button bg-green-500 rounded p-1 text-white hover:bg-green-800 ml-12 my-5 p-2"
                    type="submit"
                  >Submit</button>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default AddTitle;
