import { withRouter } from "react-router-dom";
import { useSubscription } from "@apollo/client";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { update } from "../../../store/reducers/session";

import Header from "../../common/ui/Header";
import AddTeam from "../../common/teams/AddTeam";
import GET_TEAMS from "../../queries/teams";

const Teams = ({ history }) => {
  const user = useSelector((state) => state.session.user);
  const updateDispatch = useDispatch();
  const [teams, setTeams] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [showAddTeam, setShowAddTeam] = useState({ show: false });
  const showAddTeamClass = showAddTeam.show === true ? "visible" : "invisible";

  const { loading: loading_teams } = useSubscription(GET_TEAMS, {
    variables: {
      party_id: user.party.partyId,
    },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (data && data.party_relationship) {
        let orgs = [];
        let call_centers = [];
        data.party_relationship.map(org => {
          if (org.partyByToPartyId.call_centers) 
            org.partyByToPartyId.call_centers.map(cc => {
              call_centers.push({...cc, party: org.partyByToPartyId});
              return cc;
            });
          orgs.push(org.partyByToPartyId);
          return org;
        });
        setOrganisations(orgs);
        setTeams(call_centers);
      }
    }
  });

  const toggleAddTeamForm = (e) => {
    if (e !== null) e.preventDefault();
    setShowAddTeam({
      show: !showAddTeam.show
    });
  };

  const updateParty = (team) => {
    updateDispatch(update({ party: { ...user.party, callCenterId: team.id, organisationId: team.party.id } }));
    history.push("/p/calls");
  };

  return (
    <div>
      <Header title="Call Centers" subtitle="Choose a call center" />
      <div className="w-full h-auto relative bg-blue-50 mt-20">
        <div className="w-auto">
          <h2 className="w-auto pl-12 pt-4 pb-3 capitalize text-gray-400 border-b">
            Call Centers
          </h2>
        </div>
      </div>
      <div className="w-full">
        <button
          onClick={e => toggleAddTeamForm(e)}
          className="w-auto h-auto float-right shadow rounded text-purple-500 bg-purple-500 px-2 py-1 mr-32 mt-5 hover:text-gray-500 hover:bg-purple-600"
        >
          <svg className="w-6 h-6 inline-block" fill="#ffffff" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
          <span className="inline-block text-sm text-white mx-1">Add Call Center</span>
        </button>
        <h3 className="w-auto pl-12 pt-4 pb-3 text-2xl text-gray-600 mt-4">Hi { user.party.name }, <br />Please select a call center to proceed </h3>
        <div className="w-11/12 m-auto mt-12 flex flex-row flex-wrap">
          {
            !loading_teams && teams.length < 1 ?
              (<div className="w-full mt-24">
                <div className="w-full">
                  <svg className="w-12 h-12 m-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                  </svg>
                </div>
                <p className="text-xl text-center mt-12">You are not attached to any call centers at the moment</p>
              </div>) :
              teams.map(team => {
                return <div
                  key={team.id}
                  onClick={e => updateParty(team)}
                  className="w-1/4 h-auto flex-none rounded shadow-xl bg-purple-300 px-4 py-8 mx-5 text-gray-800 hover:cursor-pointer hover:bg-purple-600 hover:text-white">
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                  <p className="text-xl mt-5">{team.name}</p>
                  <p className="text-xs mt-2">At {team.party.name}</p>
                </div>;
              })
          }
        </div>
        <AddTeam organisations={organisations} showAddTeamClass={showAddTeamClass} toggleAddTeamForm={toggleAddTeamForm} />
      </div>
    </div>
  )
}

export default withRouter(Teams);
