import axios from 'axios';

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    // baseURL: '/api/',
    baseURL: 'http://localhost:1599/',
    // baseURL: 'https://sandbox.swifttdial.com:2778/api/',
    // baseURL: 'https://voice.swifttdial.com/',
    timeout: 9000,
});

// // Response interceptor to serve as middleware after reciving response
// axiosIns.interceptors.response.use(
//   response => response,
//   error => {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     if (error && error.response && error.response.status === 401) {
//       router.push({ name: 'auth-login' })
//     }
//     return Promise.reject(error)
//   },
// )

// // Request interceptor to serve as middleware before sending request
// axiosIns.interceptors.request.use(
//   config => config,
//   error => {
//     // Do something with request error
//     return Promise.reject(error)
//   },
// )

export default axiosIns;

