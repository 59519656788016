import { Route, Redirect, Switch } from 'react-router-dom'
import { useState } from "react";
import { useSelector } from 'react-redux';

import Teams from './teams';
import Transactions from './transactions';
import Calls from './calls';
import Contact from './contacts';
import Phones from './phones';
import Dashboard from './dashboard';
import IVR from './ivr';
import Nav from '../common/nav';
import Settings from './settings';
import Queue from './queue';
import Members from './members';
import Profile from './profile';
import Login from '../auth/Login';


const PageIndex = () => {
  const user = useSelector((state) => state.session.user);
  const [isOpen, setIsOpen] = useState(true);
  let containerClass = isOpen ? 'w-full xl:w-9/12 2xl:w-10/12 left-0 xl:left-1/4 2xl:-ml-32 3xl:-ml-40' : 'w-full left-0';

  return (
    <div className='min-h-screen relative'>
      <button
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
        className="w-11 h-11 fixed flex-none inset-0 top-24 -mt-3 z-50 bg-gray-800">
        <svg className="w-5 h-5 ml-3 mt-0" fill="none" stroke="white" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>
      <div className="w-full relative text-sm">
        <Nav isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className={"absolute top-0 " + containerClass}>
            <Switch>
              {/* <Route path="/p/teams" exact component={Teams} /> */}
              <Route path="/p/profile" component={Profile} />
              <Route path="/p/logout" component={Login} />
              <Route path="/p/transactions" exact component={Transactions} />
              <Route path="/p/calls" exact component={Calls} />
              <Route path="/p/calls/outgoing/:destination" exact component={Calls} />
              <Route path="/p/calls/refer/:referral" exact component={Calls} />
              <Route path="/p/call/:callId" exact component={Calls} />
              <Route path="/p/queue" exact component={Queue} />
              <Route path="/p/phones" exact component={Phones} />
              <Route path="/p/dashboard" exact component={Dashboard} />
              <Route path="/p/ivr" exact component={IVR} />
              <Route path="/p/contacts" component={Contact} />
              <Route path="/p/settings" component={Settings} />
              <Route path="/p/members" component={Members} />
              <Redirect to="/p/calls" />
            </Switch>
        </div>
      </div>
    </div>
  )
}

export default PageIndex