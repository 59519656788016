const initialElements = [
    {
        id: 'node-0',
        type: 'ivr_node',
        position: {
            x: 350,
            y: 50
        },
        data: {id: "node-0", description: "Speak to an agent", type: "agent", audio_url: "", dtmf_digits: 1, color: "white", selected: true, allow_skip: false, repeat_msg: 0, },
    },
    {
        id: 'node-1',
        type: 'ivr_node',
        position: {
            x: 150,
            y: 200
        },
        data: {id: "node-1", source: "node-0", description: "Attach an audio file", type: "audio", audio_url: "", dtmf_digits: 1, color: "green-400", selected: false, allow_skip: false, repeat_msg: 0,},
    },
    {
        id: 'node-2',
        type: 'ivr_node',
        position: {
            x: 550,
            y: 200
        },
        data: {id: "node-2", source: "node-0", description: "All our agents are occupied", type: "unavailable", audio_url: "", dtmf_digits: 2, color: "indigo-400", selected: false, allow_skip: false, repeat_msg: 0,},
    },
    {
        id: 'node-3',
        type: 'ivr_node',
        position: {
            x: 550,
            y: 50
        },
        data: {id: "node-3", description: "Beyond working hours", type: "beyond", audio_url: "", dtmf_digits: 1, color: "red-400", selected: false, allow_skip: false, repeat_msg: 0,},
    },
    {
        id: 'e0-1',
        source: 'node-0',
        target: 'node-1',
        type: 'smooth-step',
        label: '#: 1',
    },
    {
        id: 'e0-2',
        source: 'node-0',
        target: 'node-2',
        type: 'smooth-step',
        label: '#: 2',
    },
];

export default initialElements;