// import { useSelector } from "react-redux";
// import { useAlert } from 'react-alert';
import { useState } from "react";

import EditParty from "../profile/EditParty";

const OrganizationTab = ({ organization, refetchOrganisation }) => {
  // const alert = useAlert();
  // const user = useSelector((state) => state.session.user);
  const [showEditParty, setShowEditParty] = useState({ show: false });
  const showEditPartyClass = showEditParty.show === true ? "visible" : "invisible";

  const toggleEditPartyForm = (e) => {
    if (e !== null) e.preventDefault();
    if (showEditParty.show) refetchOrganisation();
    setShowEditParty({
      show: !showEditParty.show
    });
  };

  return (
    <div>
      <div className="p-4">
        <button
          onClick={e => toggleEditPartyForm(e)}
          className="float-right shadow rounded text-purple-500 bg-purple-500 px-2 py-1 m-0 hover:text-gray-500 hover:bg-purple-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="white">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
          </svg>
          <span className="inline-block text-sm text-white mx-2">Edit</span>
        </button>
        <div className="w-4/5">
          <h3 className="text-sm font-medium text-gray-500 uppercase">Basic Details</h3>
          <div className="w-full bg-white rounded border-l-2 border-purple-500 p-4 my-4">
            <p className="text-base my-2"><span className="text-purple-500">Institution Name:</span> {organization.institutionName}</p>
            <p className="text-base my-2"><span className="text-purple-500">Legal Name:</span> {organization.legalName}</p>
            <p className="text-base my-2"><span className="text-purple-500">Institution Tagline:</span> {organization.tagline}</p>
            <p className="text-base my-2"><span className="text-purple-500">Institution Code:</span> {organization.code}</p>
          </div>
        </div>
        <div className="w-4/5">
          <h3 className="text-sm font-medium text-gray-500 uppercase">Address Details</h3>
          <div className="w-full bg-white rounded border-l-2 border-purple-500 p-4 my-4">
            <p className="text-base my-2"><span className="text-purple-500">Country:</span> {organization.address.country.name}</p>
            <p className="text-base my-2"><span className="text-purple-500">City:</span> {organization.address.city}</p>
            <p className="text-base my-2"><span className="text-purple-500">Physical/Street Address:</span> {organization.address.streetAddress}</p>
            <p className="text-base my-2"><span className="text-purple-500">Postal Address:</span> {organization.address.postalAddress}</p>
            <p className="text-base my-2"><span className="text-purple-500">Postal Code:</span> {organization.address.postalCode}</p>
          </div>
        </div>
        <div className="w-4/5">
          <h3 className="text-sm font-medium text-gray-500 uppercase">Contact Details</h3>
          <div className="w-full bg-white rounded border-l-2 border-purple-500 p-4 my-4">
            <p className="text-base my-2"><span className="text-purple-500">Email Address:</span> {organization.emailAddress}</p>
            <p className="text-base my-2"><span className="text-purple-500">Default Contact:</span> {organization.phoneNumber}</p>
            <p className="text-base my-2"><span className="text-purple-500">Website:</span> {organization.websiteUrl}</p>
          </div>
        </div>
      </div>
      <EditParty showEditPartyClass={showEditPartyClass} toggleEditPartyForm={toggleEditPartyForm} party={organization}/>
    </div>
  );
};


export default OrganizationTab;