import { AT_API_KEY } from '../utils/config';
import axios from 'axios';

function getQueue({ queryKey }){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: 'https://voice.sandbox.africastalking.com/queueStatus', 
      data: {...body.payload, apiKey: AT_API_KEY}
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default getQueue;
