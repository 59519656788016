import axios from '../utils/axios';

function resetPassword({queryKey}){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios
      .post('/register', body.payload)
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default resetPassword;