import { useState } from "react";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import addTransaction from "../../mutations/AddTransaction";

const AddTransaction = ({ showAddTransactionClass, toggleAddTransactionForm }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const [loading, setLoading] = useState(false);
  const className = `add-transaction-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddTransactionClass}`;

  // const [addTransaction, { loading }] = useMutation(ADD_TRANSACTION, {
  //   onCompleted: () => {
  //     toggleAddTransactionForm(null);
  //     alert.success("STK Push sent! Please check your phone", {timeout: 10000})
  //   },
  //   onError: (error) => {
  //     alert.error(error.message);
  //     // toggleAddTransactionForm(null);
  //     console.log(error);
  //   },
  // });

  const doCheckout = useMutation(addTransaction, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      toggleAddTransactionForm(null);
      alert.success("STK Push sent! Please check your phone", {timeout: 10000});
    },
  });
  
  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Buy Airtime
        </h1>
        <button
          type="button"
          onClick={e => toggleAddTransactionForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <Formik
          initialValues={{
            phone: "",
            amount: ""
          }}
          onSubmit={({ phone, amount }, { resetForm }) => {
            doCheckout({
              "callCenterId": user.party.callCenterId,
              "phoneNumber": phone,
              "amount": parseInt(amount)
            });
            resetForm({
              phone: "",
              amount: ""
            });
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="phone"
                  placeholder="Enter your phone number"
                  disabled={loading}
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="amount"
                  placeholder="Enter the amount to top up"
                  disabled={loading}
                />
                <button
                  disabled={loading}
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Pay</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default AddTransaction;
