import React, { useEffect, useMemo } from "react";
// import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

import getCallCount from '../../queries/call_count';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const CallChart = ({ days }) => {
  const user = useSelector((state) => state.session.user);
  const from = moment().subtract(days, 'days').local().format("YYYY-MM-DD");
  const to = moment().local().format("YYYY-MM-DD");

  const { loading, data, refetch: fetchAgentCalls } = useQuery(['get_call_count', {
    params: {from: from, to: to, token: user.token}, 
    payload: {}
  }], getCallCount, {
    enabled: false
  });

  const cleaned = useMemo(() => {
    let cleaned_data = {};
    if(data?.data?.content)
      data.data.content.map(({ publicId, createdDate }) => {
        const fdate = moment(createdDate).format("YYYY-MM-DD");
        if (!Object.keys(cleaned_data).includes(fdate)) cleaned_data[fdate] = 0;
        else cleaned_data[fdate] = cleaned_data[fdate] + 1;
        return { publicId, createdDate };
      });
    return cleaned_data;
  }, [loading]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
    },
  };

  const callData = useMemo(
    () => ({
      labels: Object.keys(cleaned),
      datasets: [
        {
          label: "Calls",
          data: Object.keys(cleaned).map(k => cleaned[k]),
          backgroundColor: 'rgba(255, 99, 132, 0.8)',
        },
      ],
    }),
    [cleaned]
  );

  useEffect(() => {
    fetchAgentCalls();
  }, []);

  return (
    <div className="h-11/12 w-11/12 mx-auto py-2">
      <Line 
        className="w-full"
        options={options} 
        data={callData} 
      />
    </div>
  );
};
export default CallChart;
