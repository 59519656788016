const IVRComponents = ({ addNode }) => {
    return (
        <div className="w-11/12 m-auto">
            <div
                onClick={() => addNode("agent")}
                className="w-3/4 m-auto py-4 mt-10 bg-gray-100 rounded shadow hover:bg-gray-200 hover:cursor-pointer"
            >
                <h3 className="text-sm text-center text-black">Agent</h3>
                <p className="text-xs text-center text-black">Speak to an agent</p>
            </div>
            <div
                onClick={() => addNode("audio")}
                className="w-3/4 m-auto py-4 mt-5 bg-green-400 rounded shadow hover:bg-green-600 hover:cursor-pointer"
            >
                <h3 className="text-sm text-center text-black">Audio</h3>
                <p className="text-xs text-center text-black">Attach an audio file</p>
            </div>
            <div
                onClick={() => addNode("unavailable")}
                className="w-3/4 m-auto py-4 mt-5 bg-indigo-400 rounded shadow hover:bg-indigo-600 hover:cursor-pointer"
            >
                <h3 className="text-sm text-center text-black">No available agent</h3>
                <p className="text-xs text-center text-black">All our agents are occupied</p>
            </div>
            <div
                onClick={() => addNode("beyond")}
                className="w-3/4 m-auto py-4 mt-5 bg-red-400 rounded shadow hover:bg-red-600 hover:cursor-pointer"
            >
                <h3 className="text-sm text-center text-black">Beyond</h3>
                <p className="text-xs text-center text-black">Beyond working hours</p>
            </div>
            <div className="w-3/4 m-auto mt-16">
                <h2 className="text-md text-black"><strong>Guide:</strong></h2>
                <p>
                    1. Click an element from the Components tab to add items<br />
                    - The components represent a single IVR option that will be set up on your call center
                    <br /><br />
                    2. To <strong>delete</strong> an element Click on It (to select) then Press <strong>backspace</strong>
                </p>
            </div>
        </div>
    );
};

export default IVRComponents;