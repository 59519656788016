import { useQuery } from "react-query";
import { useSelector } from "react-redux";

import CallDetails from "../../common/callDetails";

import getCallDetails from "../../queries/call_detail";

const CallDetail = ({ callId }) => {
  const user = useSelector((state) => state.session.user);
  const { isLoading, data: call, refetch: refetchCall } = useQuery(['get_call_logs', {
    params: {id: callId, token: user.token}, 
    payload: {}
  }], getCallDetails);

  return call === undefined ? null : <div><CallDetails call={call.data} refetchCall={refetchCall}/></div>;
};

export default CallDetail;
