import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useAlert } from 'react-alert';
import Switch from "react-switch";
// import { HomeIcon } from "@heroicons/react/outline";
import { useQuery } from "react-query";
import { update as update_user_session } from "../../../store/reducers/session";
import { update as update_sip_session } from "../../../store/reducers/sipSession";

import USER_DETAILS from "../../queries/user_details";
import TOPUPS_SUM from "../../queries/total_topups";
import CALL_COST_SUM from "../../queries/total_call_cost";

const Header = ({ title, subtitle }) => {
  // const alert = useAlert();
  const data = null;
  const updateDispatch = useDispatch();
  const [onlineStatus, setOnlineStatus] = useState(false);
  const user = useSelector((state) => state.session.user);
  const status = useSelector((state) => state.sipSession.online_status);

  // useQuery(USER_DETAILS, {
  //   variables: {
  //     "party_id": user.party.partyId
  //   },
  //   onCompleted: (data) => {
  //     // console.log("USER DETAILS:", data);
  //     if (data && data.user.length > 0) {
  //       updateDispatch(update_user_session({ party: { ...user.party, name: data.user[0].party.name } }));
  //     }
  //   }
  // });

  // useQuery(TOPUPS_SUM, {
  //   variables: {
  //     call_center_id: user.party.callCenterId,
  //   },
  //   onCompleted: (data) => {
  //     // console.log("TOPUPS:", data);
  //     if (data && data.call_center_by_pk && data.call_center_by_pk.chart_of_account) 
  //       updateDispatch(update_user_session({ party: { ...user.party, topups: data.call_center_by_pk.chart_of_account.transactions_aggregate.aggregate.sum.amount } }));
  //   }
  // });

  // useQuery(CALL_COST_SUM, {
  //   variables: {
  //     call_center_id: user.party.callCenterId,
  //   }, 
  //   onCompleted: (data) => {
  //     // console.log("CALL COST:", data);
  //     if (data && data.call_log_cost_aggregate) 
  //       updateDispatch(update_user_session({ party: { ...user.party, call_cost: data.call_log_cost_aggregate.aggregate.sum.charge } }));
  //   }
  // });

  const setStatus = (stat) => {
    updateDispatch(update_sip_session({ online: stat }));
    setOnlineStatus(stat);
    console.log("Online: ", status);
  };

  useEffect(() => {
    setStatus(status.online);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateOnlineStatus = (event) => {
      if (window.navigator.onLine) {
        setStatus(true);
        window.removeEventListener("online", updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);
      } else {
        setStatus(false);
        window.removeEventListener("offline", updateOnlineStatus);
        window.addEventListener('online', updateOnlineStatus);
      }
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineStatus]);

  // const noTopUpsNoCost = (user.party.topups === undefined || user.party.topups === null) && (user.party.call_cost === undefined || user.party.call_cost === null);
  // const topUpsNoCost = (user.party.topups !== undefined || user.party.topups !== null) && (user.party.call_cost === undefined || user.party.call_cost === null);

  const noTopUpsNoCost = true;
  const topUpsNoCost = false;

  return (
    <div className="w-full p-4 fixed top-0 shadow-lg bg-gray-100 z-20">
      {/* <div className="w-fit">
        <span className="w-auto h-auto">
          <HomeIcon className="h-6 w-6 inline-block" />
        </span>
      </div> */}

      <div className="w-full inline-block">
        <h1 className="text-2xl font-bold">{title}</h1>
        <div className="text-gray-400">{subtitle}</div>
      </div>

      <div className="flex w-fit fixed top-5 right-5">
        <Switch
          className="flex-none w-20 mt-3"
          onChange={e => setStatus(e)}
          checked={onlineStatus}
        />
        <div className="flex-auto">
          <h2 className="w-auto text-lg text-black text-right">
            {user.user.fullName === undefined ? 'Welcome, guest' : `Welcome ${user.user.fullName}`}
          </h2>
          <p className="w-auto text-right">
            {
              user.user.currency_code === undefined ? 
              'KES ' : String(user.user.currency_code) + " "
            } 
            {
              noTopUpsNoCost ? "0.00" 
              : topUpsNoCost ? parseFloat(user.party.topups).toFixed(2) 
              : parseFloat(parseFloat(user.user.topups) - (parseFloat(user.user.call_cost) / 100)).toFixed(2)
            }
          </p>
        </div>
      </div>
    </div>
  )
}

export default Header