import { gql } from "@apollo/client";

const USER_DETAILS = gql`
query GetUserDetails($party_id: uuid!) {
  user(where:{party_id:{_eq: $party_id}}) {
    id
    party{
      name
    }
  }
}
`;

export default USER_DETAILS;