import { useQuery } from "@apollo/client";
import { format, parseISO } from "date-fns"
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Header from "../../common/ui/Header";
import StyledPaginate from '../../common/ui/StyledPaginate';
import AutoCompleteInput from "../../common/ui/AutoCompleteInput";
import AddTransaction from "../../common/transactions/AddTransaction";
import TRANSACTIONS from "../../queries/transactions";

const TransactionsIndex = () => {
  const user = useSelector((state) => state.session.user);
  const [transactions, setTransactions] = useState([]);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemOffset, setItemOffset] = useState(-1);
  // eslint-disable-next-line no-unused-vars
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [showAddTransaction, setShowAddTransaction] = useState({ show: false });
  const showAddTransactionClass = showAddTransaction.show === true ? "block" : "hidden";

  const { data, loading } = useQuery(TRANSACTIONS, {
    variables: {
      call_center_id: user.party.callCenterId,
    },
    onCompleted: (data) => {
      if (data?.callCenter?.account?.transactions.length > 0) {
        setTransactions(data.callCenter.account.transactions);
        setCurrentItems(data.callCenter.account.transactions.slice(0, itemsPerPage));
        setItemOffset(0);
      }
    }
  });

  const toggleAddTransactionForm = (e) => {
    if (e !== null) e.preventDefault();
    setShowAddTransaction({
      show: !showAddTransaction.show
    });
  };

  const filterTransactions = (search, setFiltered) => {
    setFiltered([]);

    let filtered = currentItems.filter((transaction) => {
      return (transaction.amount !== null && (transaction.reference.toLowerCase().includes(search.toLowerCase()) ||
        String(transaction.amount).toLowerCase().includes(search.toLowerCase())));
    });

    filtered = filtered.map((transaction) => {
      return { id: transaction.id, suggestion: transaction.reference + " : Ksh. " + transaction.amount, data: transaction }
    });

    setFiltered(filtered);
  };

  const setCurrentTransaction = (e, transaction) => {
    if (e !== null) e.preventDefault();
    setCurrentItems([transaction]);
  };

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    if (transactions.length > 0) {
      console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(transactions.slice(itemOffset, endOffset));
      console.log("Loaded Items: ", currentItems);
      setPageCount(Math.ceil(transactions.length / itemsPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % transactions.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div>
      <Header title="Transactions" subtitle="A statement of Transactions" />
      <div className="bg-blue-50 min-h-screen mt-20 relative">
        {loading ? null : (
          <div className="w-full">
            <div className="w-full">
              <h3 className="pl-4 pt-4 pb-3 capitalize text-gray-400 border-b">
                {data === undefined ? null : data.callCenter.account.name + "(" + data.callCenter.account.currency_code + ")"}
              </h3>
              <div className="w-11/12 m-auto">
                <button
                  onClick={e => toggleAddTransactionForm(e)}
                  className="w-auto h-auto float-right shadow rounded text-purple-500 bg-purple-500 px-2 py-1 my-5 z-50 hover:text-gray-500 hover:bg-purple-600"
                >
                  <svg className="w-6 h-6 inline-block" fill="#ffffff" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                  </svg>
                  <span className="inline-block text-sm text-white mx-1">Top Up</span>
                </button>
              </div>
              <AutoCompleteInput
                className="w-11/12 m-auto mt-16"
                loading={loading}
                placeholder="Search for a transaction"
                perfomFilter={filterTransactions}
                setSuggestion={setCurrentTransaction}
              />
              {
                !loading && currentItems.length < 1 ?
                  <p className="text-center text-lg mt-20">No transactions for this account</p> :
                  <Transactions transactions={currentItems} />
              }
            </div>
            <StyledPaginate
              nextLabel="next >"
              previousLabel="< previous"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        )}
        <AddTransaction showAddTransactionClass={showAddTransactionClass} toggleAddTransactionForm={toggleAddTransactionForm} />
      </div>
    </div>
  );
};

const Transactions = ({ transactions }) => {
  return (
    <div className="w-full p-4 overflow-x-auto">
      <table className="w-11/12 mx-auto table divide-y divide-gray-200 bg-white shadow-xl">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Date</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-left">Description</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-left">Reference</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Cr</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Dr</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-right">Balance</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((trans) => (
            <tr key={trans.id} className="hover:bg-blue-100 cursor-pointer">
              <td className="px-6 py-3">{format(parseISO(trans.transaction_date), 'MM/dd/yyyy HH:mm')}</td>
              <td className="px-6 py-3">{trans.description}</td>
              <td className="px-6 py-3">{trans.reference}</td>
              <td className="px-6 py-3  text-right">{trans.record_type === 1 ? trans.amount : null}</td>
              <td className="px-6 py-3  text-right">{trans.record_type !== 1 ? trans.amount : null}</td>
              <td className="px-6 py-3  text-right">{trans.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default TransactionsIndex;
