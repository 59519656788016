import { useState } from "react";
// import { useSelector } from "react-redux";
import AddDepartment from "./AddDepartment";
import EditDepartment from "./EditDepartment";

const DepartmentsTab = ({ departments, refetchDepartments }) => {
  // const user = useSelector((state) => state.session.user);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [showAddDepartment, setShowAddDepartment] = useState({ show: false });
  const [showEditDepartment, setShowEditDepartment] = useState({ show: false });
  const showAddDepartmentClass = showAddDepartment.show === true ? "visible" : "invisible";
  const showEditDepartmentClass = showEditDepartment.show === true ? "visible" : "invisible";

  const toggleAddDepartmentForm = (e) => {
    if (e !== null) e.preventDefault();
    if (showAddDepartment.show) refetchDepartments();
    setShowAddDepartment({
      show: !showAddDepartment.show
    });
  };

  const toggleEditDepartmentForm = (e, department = null) => {
    if (e !== null) e.preventDefault();
    setSelectedDepartment(department);
    setShowEditDepartment({
      show: !showEditDepartment.show
    });
  };

  return (
    <div className="p-4">
      <button
        onClick={e => toggleAddDepartmentForm(e)}
        className="w-auto h-auto bg-purple-500 rounded float-right text-white px-3 py-1 mr-10">
        <svg className="w-6 h-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Add Department
      </button>
      <h3 className="text-sm font-medium text-gray-500 uppercase">Departments</h3>
      <table className="w-4/5 table divide-y divide-gray-200 bg-white shadow-xl mt-10">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Name</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Can bill?</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Ward</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Merchant</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Parent</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Edit</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Delete</th> */}
          </tr>
        </thead>
        <tbody>
          {
            departments.map((department) => (
              <tr key={department.id} className="hover:bg-blue-100 cursor-pointer">
                <td className="px-6 py-3 text-left">{department.name}</td>
                <td className="px-6 py-3 text-left">{!department.invoicingDepartment ? "False" : "Yes"}</td>
                <td className="px-6 py-3 text-left">{!department.ward ? "False" : "Yes"}</td>
                <td className="px-6 py-3 text-left">{!department.merchantDepartment ? "False" : "Yes"}</td>
                <td className="px-6 py-3 text-left">{department.parentDepartment === null ? "" : department.parentDepartment.name}</td>
                <td className="px-6 py-3 text-right">
                  <button
                    onClick={e => toggleEditDepartmentForm(e, department)}
                    className="shadow rounded text-white bg-purple-500 p-1 hover:text-white-600 hover:bg-purple-600"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </td>
                {/* <td className="px-6 py-3 text-right">
                  <button
                    className="shadow rounded text-white bg-red-500 p-1 hover:text-white-600 hover:bg-red-600"
                  >
                    <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </td> */}
              </tr>
            ))
          }
        </tbody>
      </table>
      <AddDepartment showAddDepartmentClass={showAddDepartmentClass} toggleAddDepartmentForm={toggleAddDepartmentForm}/>
      {
        selectedDepartment === null ? 
          null : 
          <EditDepartment showEditDepartmentClass={showEditDepartmentClass} toggleEditDepartmentForm={toggleEditDepartmentForm} department={selectedDepartment}/>
      }
    </div>
  );
};

export default DepartmentsTab;