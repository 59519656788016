import { BASE_URL, API_VERSION, AT_PORT } from '../utils/config';
import axios from 'axios';

function exportContacts({ params, payload }){
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: BASE_URL + AT_PORT + API_VERSION + '/contacts/export', 
      headers: {
        'Authorization': `Bearer ${params.token}`
      },
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default exportContacts;