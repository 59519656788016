import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from "react-query";
import { useSelector } from 'react-redux';
import { useAlert } from 'react-alert';
import Contacts from "../../common/contacts";
import ContactNav from "../../common/contacts/ContactNav";
import ContactDetail from "../../common/contacts/ContactDetail";
import AddContact from "../../common/contacts/AddContact";
import Header from "../../common/ui/Header";
import ExportForm from "../../common/ui/ExportForm";
import getContacts from "../../queries/contacts";
import exportContacts from "../../mutations/ExportContacts";

const ContactIndex = () => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const [currentContact, setCurrentContact] = useState(null);
  const [showAddContact, setShowAddContact] = useState({ show: false });
  const showAddContactClass = showAddContact.show ? "visible" : "invisible";

  const { isLoading, data: contacts, refetch: refetchContacts } = useQuery(['get_contacts', {
    params: {token: user.token}, 
    payload: {}
  }], getContacts);

  const changeCurrentContact = (e, contact) => {
    if (e !== null) e.preventDefault();
    setCurrentContact(contact);
  };

  const toggleAddContactForm = (e) => {
    if (e !== null) e.preventDefault();
    if (showAddContact.show) refetchContacts();
    setShowAddContact({
      show: !showAddContact.show
    });
  };

  const exportAllContacts = (e) => {
    e.preventDefault();
    alert.success('Are you sure you export all institution contacts?', {
      timeout: 15000,
      action: () => {
        doExport.mutate({
          payload: {},
          params: {token: user.token}
        });
      }
    });
  };

  const doExport = useMutation(payload => exportContacts(payload), {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      if(data){
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([data.data], {type: "text/plain"}));
        a.download = `contacts.csv`;
        a.click(); 
      }
    },
  });

  return (
    <div className="h-screen">
      <Header title="Contacts" subtitle="Contact List" />
      <div className="h-full bg-blue-50 flex flex-row mt-20">
        <div className="w-4/12 h-full lg:w-3/12 h-auto flex-none overflow-y-scroll">
          <h2 className="pl-5 pt-2 pb-5 mt-4 capitalize text-2xl text-gray-400 border-b">
            Contact List
          </h2>
          { 
            contacts?.data?.content === undefined ? 
              null : 
              <Contacts contacts={contacts?.data?.content} changeCurrentContact={changeCurrentContact} /> 
          }
        </div>
        <div className="flex-auto h-fit overflow-y-scroll relative">
          <ContactNav isLoading={isLoading} contacts={contacts?.data?.content} toggleAddContactForm={toggleAddContactForm} changeCurrentContact={changeCurrentContact} exportContacts={exportAllContacts}/>
          {
            currentContact === null ?
              <div className="w-full h-fit absolute top-96 left-0 text-black-500 text-md text-center">No contact selected</div> :
              <ContactDetail className="p-5 bg-gray-50 flex items-center justify-center" currentContact={currentContact} />
          }
        </div>
      </div>
      <AddContact toggleAddContactForm={toggleAddContactForm} showAddContactClass={showAddContactClass} />
    </div>
  );
};

export default ContactIndex;
