import { BASE_URL, API_VERSION, AT_PORT } from '../../utils/config';
import { useQuery, useMutation } from "react-query";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { update } from "../../../store/reducers/session";
import { useAlert } from 'react-alert';   
import { AT_API_KEY } from "../../utils/config";
import Africastalking from 'africastalking-client';
import axios from "axios";

import Calls from "../../common/callLogs";
import Phone from "../../common/ui/Phone";
import CallDetail from "../../common/callDetails/CallDetail";
import Incoming from '../../common/callLogs/Incoming';
import Outgoing from '../../common/callLogs/Outgoing';
import Header from "../../common/ui/Header";
import getCallLogs from "../../queries/call";
import ExportForm from "../../common/ui/ExportForm";
import exportCalls from "../../mutations/ExportCalls";  

const CallIndex = ({ match, history }) => {
  const alert = useAlert();
  const updateDispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const onlineStatus = useSelector((state) => state.sipSession.online_status);
  const [muted, setMuted] = useState(false);
  const [onHold, setOnHold] = useState(false);
  const [incoming, setIncoming] = useState(false);
  const [received, setReceived] = useState(false);
  const [outgoing, setOutgoing] = useState("");
  const [destination, setDestination] = useState("");
  const [displayName, setDisplayName] = useState("");  
  const [showExport, setShowExport] = useState({ show: false });
  const [exportData, setExportData] = useState({});
  const showExportClass = showExport.show ? "visible" : "invisible";

  const refetchAtToken = async () => {
    console.log(user.user.token);
    await axios({
      method: 'get',
      url: BASE_URL + AT_PORT + API_VERSION + `/ivr/webrtc/${user.user.institution}`,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user.token}`
      }
    })
    .then(response => {
      updateDispatch(update({...user, atToken: {...response.data, lastUpdate: new Date().getTime()}}));
    })
    .catch(error => console.log(error));
  };

  if (!!user && user?.user?.atToken !== undefined) {
    var exp = new Date(user.user.atToken.lastUpdate);
    var now = new Date();
    console.log("Time delta: ", now.getTime() - exp.getTime());
    if (now.getTime() - exp.getTime() <= (user.user.atToken.lifeTimeSec * 1000)) {
      refetchAtToken();
    }
  } else if (!!user) {
    refetchAtToken();
  }

  const params = {
    sounds: {
        dialing: '/dial.mp3',
        ringing: '/sounds/ring.mp3',
    },
  };

  const atClient = new Africastalking.Client(user?.user?.atToken?.token);

  // dom elements for playing audio (/public/index.html)
  const mediaElement = document.getElementById('remoteAudio');
  const callAudio = document.getElementById('callAudio');

  const { isLoading, data: calls, refetch: refetchCalls } = useQuery(['get_call_logs', {
    params: {
      token: user.token
    }, 
    payload: {}
  }], getCallLogs);
      
  const re = /^0\d{9}$/;
  const testPhone = (phone) => {
    return re.exec(phone) !== null;
  };

  const startCall = (dest) => {
    setOutgoing(true);
    setDestination(dest);
    console.log("Calling ", dest);
    if (testPhone(dest)) atClient.call("+254" + String(dest.substring(1, dest.length)));
    else atClient.call(dest);
  };

  const answerCall = (e) => {
    e.preventDefault();
    atClient.answer();
  };

  const hangupCall = () => {
    atClient.hangup();
  };

  const toggleMute = (e) => {
    e.preventDefault();
    if (muted) atClient.unmute();
    else atClient.mute();
    setMuted(!muted);
  };

  const toggleHold = (e) => {
    e.preventDefault();
    if (onHold) atClient.unhold();
    else atClient.hold();
    setOnHold(!onHold);
  };

  atClient.on('offline', function () {
    alert.info('You are offline');
  }, false);

  atClient.on('incomingcall', function (params) {
    alert.info(`${params.from} is calling you`);
    setDisplayName(params.from);
    setIncoming(true);
  }, false);
  
  atClient.on('callaccepted', function(){
    setReceived(true);
  }, false);

  atClient.on('hangup', function (hangupCause) {
    alert.info(`Call hung up (${hangupCause.code} - ${hangupCause.reason})`);
    setReceived(false);
    refetchCalls();
    setTimeout(() => {
      setIncoming(false);
      setOutgoing(false);
    }, 1000);
  }, false);

  useEffect(() => {
    if (match.params.destination !== undefined) {
      startCall(match.params.destination);
    }
  }, [match.params]);


  const toggleExportForm = (e) => {
    if (e !== null) e.preventDefault();
    setShowExport({
      show: !showExport.show
    });
  };

  const doExport = useMutation(payload => exportCalls(payload), {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      if(data){
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([data.data], {type: "text/plain"}));
        a.download = `calls-${variables.payload.startDate}-${variables.payload.startDate}.csv`;
        a.click(); 
      }
    },
  });

  useEffect(() => {
    if (!!exportData)
      doExport.mutate({
        payload: exportData,
        params: {token: user.token}
      });
  }, [exportData]);

  // useEffect(() => {
  //   if (!onlineStatus.online) stopAgent();
  //   else reconnectAgent();
  //   if (user && user.extension !== undefined)
  //     updateExt({
  //       variables: {
  //         "ext_id": {"id": user.extension.extension},
  //         "ext": {
  //           "is_online": onlineStatus.online
  //         }
  //       }
  //     });
  //     // eslint-disable-next-line react-hooks/exhaustive-deps 
  // }, [onlineStatus]);

  return (
    <div className="relative min-h-screen">
      {!incoming ? null : <Incoming mediaElement={mediaElement} callAudio={callAudio} answerCall={answerCall} hangupCall={hangupCall} toggleMute={toggleMute} toggleHold={toggleHold} displayName={displayName} received={received} muted={muted} onHold={onHold} />}
      {!outgoing ? null : <Outgoing mediaElement={mediaElement} callAudio={callAudio} destination={destination} hangupCall={hangupCall} toggleMute={toggleMute} toggleHold={toggleHold} received={received} muted={muted} onHold={onHold} />}
      <Header title="Call Log" subtitle="Call management" />
      <div className="h-auto bg-blue-50 flex flex-row mt-20">
        <div className="flex-none w-2/6 border max-h-screen overflow-scroll">
          <h2 className="pl-12 pt-4 pb-3 capitalize  text-gray-400 border-b ">
            Recent Calls
          </h2>
          {calls?.data?.content === undefined ? <b>Loading</b> : <Calls calls={calls.data.content} toggleExportForm={toggleExportForm}/>}
        </div>
        <div className="flex-grow max-h-screen overflow-scroll">
          {match.params.callId ? (
            <CallDetail callId={match.params.callId}/>
          ) : (
            <div className="p-5 bg-gray-50 min-h-screen flex items-center justify-center">
              No Call Selected
            </div>
          )}
        </div>
      </div>
      <ExportForm toggleExportForm={toggleExportForm} showExportClass={showExportClass} setExportData={setExportData}/>
      <Phone isLoading={isLoading} startCall={startCall} />
    </div>
  );
};

export default CallIndex;
