import { Formik, Field, Form } from "formik";

const loading = false;

const PermissionsTab = ({ permissions, formActive, setFormActive }) => {
  const formClass = formActive.permissions ? "visible" : "invisible";

  return (
    <div className="p-4">
      <h3 className="text-sm font-medium text-gray-500 uppercase">Permissions</h3>
      <table className="w-4/5 table divide-y divide-gray-200 bg-white shadow-xl mt-10">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Name</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Description</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Edit</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Delete</th> */}
          </tr>
        </thead>
        <tbody>
          {permissions.map((permission) => (
            <tr key={permission.id} className="hover:bg-blue-100 cursor-pointer">
              <td className="px-6 py-3 text-left">{permission.name}</td>
              <td className="px-6 py-3 text-left">{permission.description}</td>
              {/* <td className="px-6 py-3 text-right">
                    <button
                        className="shadow rounded text-white bg-purple-500 p-1 hover:text-white-600 hover:bg-purple-600"
                        onClick={e => setFormActive({ organization: false, departments: false, permissions: true, roles: false })}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                        </svg>
                    </button>
                </td>
                <td className="px-6 py-3 text-right">
                    <button
                        className="shadow rounded text-white bg-red-500 p-1 hover:text-white-600 hover:bg-red-600"
                    >
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={"w-screen h-screen overlay absolute -inset-1/4 ml-7 -mt-40 overflow-y-scroll bg-black bg-opacity-50 z-50 " + formClass}>
        <div className="w-6/12 h-auto absolute top-64 left-2/4 -ml-96 bg-white rounded-sm shadow p-4">
          <button
            type="button"
            className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
            onClick={e => setFormActive({ organization: false, departments: false, permissions: false, roles: false })}>
            <span className="sr-only">Close menu</span>
            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <h2 className="w-auto text-xl text-black uppercase my-2 ml-10">Edit user permission details</h2>
          <Formik
            initialValues={{
              name: "",
              description: ""
            }}

            onSubmit={(values, { resetForm }) => {
              resetForm({
                name: "",
                description: ""
              });
            }}
          >
            {
              formik => (
                <Form >
                  <div className="w-11/12 m-auto py-2">
                    <h3 className="text-sm font-medium text-gray-500 border-b my-2">User permission details</h3>
                    <Field
                      className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="name"
                      placeholder="Enter the user permission name"
                      disabled={loading}
                    />
                    <Field
                      className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="description"
                      placeholder="Enter the user permission description"
                      disabled={loading}
                    />
                  </div>
                  <button
                    disabled={loading}
                    className="w-auto button bg-green-500 rounded p-1 text-white hover:bg-green-800 ml-12 my-5 p-2"
                    type="submit"
                  >Save changes</button>
                </Form>
              )
            }
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PermissionsTab;