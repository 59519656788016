import AutoCompleteInput from "../ui/AutoCompleteInput";

const ContactNav = ({ loading, contacts, toggleAddContactForm, changeCurrentContact, exportContacts }) => {

  const filterContacts = (search, setFiltered) => {
    setFiltered([]);
    let filtered = contacts.filter((contact) => {
      return (contact.phone !== null && (contact.name.toLowerCase().includes(search.toLowerCase()) || 
      contact.phone.toLowerCase().includes(search.toLowerCase())));
    });
    filtered = filtered.map((contact) => {
      return {id: contact.id, suggestion: contact.name + " : " + contact.phone, data: contact}
    });
    setFiltered(filtered);
  };

  return (
    <div className="w-full flex">
      <AutoCompleteInput loading={loading} placeholder="Search for contact" perfomFilter={filterContacts} setSuggestion={changeCurrentContact}/>
      <div className="flex flex-none justify-end mx-2 mt-2">
        <button
          disabled={loading}
          className="button h-8 bg-green-500 rounded text-white hover:bg-green-800 mt-5 ml-0 p-1"
          type="button"
          onClick={toggleAddContactForm}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
          </svg>
        </button>
        {/* <button
          disabled={loading}
          className="button h-8 bg-purple-500 rounded text-white hover:bg-purple-800 mt-5 ml-2 p-1"
          type="button"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
          </svg>
        </button> */}
        <button
          disabled={loading}
          className="button h-8 bg-indigo-500 rounded text-white hover:bg-indigo-800 mt-5 ml-2 p-1"
          type="button"
          onClick={e => exportContacts(e)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
          </svg>
        </button>
        {/* <button
          disabled={loading}
          className="button h-8 bg-red-500 rounded text-white hover:bg-red-800 mt-5 ml-2 p-1"
          type="button"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
          </svg>
        </button> */}
      </div>
    </div>
  );
};

export default ContactNav;