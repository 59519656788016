import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
// import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import EDIT_MEMBER from "../../mutations/UpdateMember";

const EditPhone = ({ showEditPhoneClass, toggleEditPhoneForm, phone }) => {
  const loading = true;
  const alert = useAlert();
  // const user = useSelector((state) => state.session.user);
  const className = `edit-phone-screen w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showEditPhoneClass}`;

  // const [editPhone, { loading }] = useMutation(EDIT_MEMBER, {
  //   onCompleted: (data) => {
  //     alert.success("Phone edited successfully!");
  //     toggleEditPhoneForm(null);
  //     setTimeout(() => {
  //       window.location.assign("/p/phones");
  //     }, 1500);
  //   }
  // });

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Edit Phone
        </h1>
        <button
          type="button"
          onClick={e => toggleEditPhoneForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <Formik
          initialValues={{
            name: "",
            password: ""
          }}

          onSubmit={({ name, password }, { resetForm }) => {
            // editPhone({
            //   variables: {
            //     "ext_id": { "id": phone.id },
            //     "ext": {
            //       "name": name,
            //       "password": password
            //     }
            //   },
            // });
            resetForm({
              name: "",
              password: ""
            });
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="name"
                  placeholder="Enter the agent's name"
                  disabled={loading}
                  required
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="name"
                  type="password"
                  placeholder="Enter the extension password"
                  disabled={loading}
                  required
                />
                <button
                  disabled={loading}
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Edit</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default EditPhone;
