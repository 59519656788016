import { Formik, Field, Form } from "formik";
import logo from "../../static/logo.png";
import { useMutation } from "@apollo/client";
import { useAlert } from 'react-alert';

import resetPassword from "../mutations/ResetPassword";


const ForgotPassword = ({ history, match }) => {
  const alert = useAlert()
  const token = match.params.token;
  console.log(token);

  const doReset = useMutation(resetPassword, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      if (data) {
        const { statusCode, message } = data;
        if (String(statusCode) === "200"){
          alert.success(message);
          history.push("/auth/login");
        } else {
          alert.error(message);
        }
      }
    },
  })

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset your password
          </h2>
        </div>
        <Formik
          initialValues={{
            password: "",
            confirm_password: "",
          }}
          onSubmit={({ password, confirm_password }) => {
            if (password === confirm_password && token)
              doReset({
                "token": token,
                "password": password,
              });
          }}
        >
          <Form className="mt-8 space-y-6" action="#" method="POST">
            <Field type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  New Password
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Enter new password"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Confirm Password
                </label>
                <Field
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Reset Password
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
