import { useState } from "react";
import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
// import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import updateDepartment from "../../mutations/UpdateDepartment";

const EditDepartment = ({ showEditDepartmentClass, toggleEditDepartmentForm, department }) => {
  delete department.__typename;
  const alert = useAlert();
  // const user = useSelector((state) => state.session.user);
  const [loading, setLoading] = useState(false);
  const className = `edit-dep-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showEditDepartmentClass}`;

  const doUpdateDepartment = useMutation(updateDepartment, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      alert.success("Department edited successfully!");
      toggleEditDepartmentForm(null);
    },
  });

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Edit Department
        </h1>
        <button
          type="button"
          onClick={e => toggleEditDepartmentForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <Formik
          initialValues={department}
          onSubmit={({ name, is_billing, is_ward, is_merchant }, { resetForm }) => {
            if (department !== null)
            doUpdateDepartment.mutate({
              "dep_id": { "id": department.id },
              "dep": {
                "name": name,
                "is_billing": (is_billing === "true"),
                "is_ward": (is_ward === "true"),
                "is_merchant": (is_merchant === "true"),
              }
            });
            resetForm({
              name: "",
              is_billing: "",
              is_ward: "",
              is_merchant: "",
            });
          }}
        >
          {
            formik => (
              <Form >
                <div className="w-11/12 m-auto py-2">
                  <h3 className="text-sm font-medium text-gray-500 border-b my-2">Department Details</h3>
                  <Field
                    className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                    as="input"
                    name="name"
                    placeholder="Enter the department name"
                    required
                    disabled={loading}
                  />
                  <label className="block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="is_billing">Can bill?</label>
                  <Field
                    className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 focus:ring-0 pl-4"
                    as="select"
                    name="is_billing"
                    disabled={loading}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <label className="block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="is_ward">Is a ward?</label>
                  <Field
                    className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 focus:ring-0 pl-4"
                    as="select"
                    name="is_ward"
                    disabled={loading}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                  <label className="block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="is_billing">Is a merchant?</label>
                  <Field
                    className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 focus:ring-0 pl-4"
                    as="select"
                    name="is_merchant"
                    disabled={loading}
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </Field>
                </div>
                <button
                  disabled={loading}
                  className="w-auto button bg-green-500 rounded p-1 text-white hover:bg-green-800 ml-12 my-5 p-2"
                  type="submit"
                >Save changes</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default EditDepartment;
