import { gql } from "@apollo/client";
const GET_TEAMS = gql`
subscription GetTeams($party_id: uuid!) {
  party_relationship(where: {from_party_id: {_eq: $party_id}}){
    id
    partyByToPartyId{
      id
      name
      call_centers{
        id
        name
      }
    }
  }
}
`;

export default GET_TEAMS;
