import { BASE_URL, API_VERSION, AUTH_TOKEN, AT_PORT } from '../utils/config';
import axios from 'axios';

function addTitle({params, payload}){
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: BASE_URL + AT_PORT + API_VERSION + '/titles', 
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${params.token}`
      },
      data: payload,
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default addTitle;