import React, { useState } from 'react';
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';
import { format, parseISO } from "date-fns";
import moment from 'moment';

import Header from "../../common/ui/Header";
import AddPhone from '../../common/phones/AddPhone';
import EditPhone from '../../common/phones/EditPhone';
import getAgents from '../../queries/agents';

const PhonesIndex = () => {
  const user = useSelector(state => state.session.user);
  const [currentPhone, setCurrentPhone] = useState(null);
  const [showAddPhone, setShowAddPhone] = useState({ show: false });
  const [showEditPhone, setShowEditPhone] = useState({ show: false });
  const showAddPhoneClass = showAddPhone.show === true ? "visible" : "invisible";
  const showEditPhoneClass = showEditPhone.show === true ? "visible" : "invisible";

  const { isLoading, data: agents } = useQuery(['get_agents', {
    params: {token: user.token}, 
    payload: {}
  }], getAgents);

  const toggleAddPhoneForm = (e) => {
    if (e !== null) e.preventDefault();
    setShowAddPhone({
      show: !showAddPhone.show
    });
  };

  const toggleEditPhoneForm = (e, phone = null) => {
    if (e !== null) e.preventDefault();
    setCurrentPhone(phone);
    setShowEditPhone({
      show: !showEditPhone.show
    });
  };

  return (
    <div className="relative h-full">
      <Header title="Phones" subtitle="Organisation Phones" />
      <div className="bg-blue-50 flex flex-row mt-20">
        <div className="flex-none w-1/5">
          <h2 className="pl-5 pt-2 pb-5 mt-4 capitalize text-2xl text-gray-400 border-b">
            Phones List
          </h2>
        </div>
      </div>
      <div className="w-full">
        {/* <button
          onClick={e => toggleAddPhoneForm(e)}
          className="w-auto h-auto bg-purple-500 rounded float-right text-white px-3 py-1 mt-5 mr-20">
          <svg className="w-6 h-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Request Phone number
        </button> */}
      </div>
      {!agents?.data?.content ? <div className="w-full block text-center text-lg mt-20">No agents at the moment</div> : <Phones agents={agents?.data?.content} toggleEditPhoneForm={toggleEditPhoneForm} />}
      <AddPhone showAddPhoneClass={showAddPhoneClass} toggleAddPhoneForm={toggleAddPhoneForm} />
      <EditPhone showEditPhoneClass={showEditPhoneClass} toggleEditPhoneForm={toggleEditPhoneForm} phone={currentPhone} />
    </div>
  );
};

const Phones = ({ agents, toggleEditPhoneForm }) => {

  const togglePassword = (e, id, real_pass) => {
    e.preventDefault();
    const passEl = document.getElementById(`pass-${id}`);
    if (passEl.innerHTML.substring(0, 1) === "*") passEl.innerHTML = real_pass;
    else passEl.innerHTML = real_pass.replace(real_pass.substring(0, (real_pass.length - 3)), "*".repeat(real_pass.length - 3));
  };

  return (
    <div className="w-full p-4">
      <table className="w-11/12 mx-auto mt-16 table divide-y divide-gray-200 bg-white shadow-xl">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Name</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12 text-center">Number</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Password</th> */}
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Date added</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Status</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Edit</th> */}
          </tr>
        </thead>
        <tbody>
          {
            agents.map((phone) => (
              <tr key={phone.id} className="hover:bg-blue-100 cursor-pointer">
                <td className="py-3 text-center">{phone.user.firstName + " " + phone.user.surname}</td>
                <td className="py-3 text-center">{phone.phoneNumber}</td>
                {/* <td className="py-3 text-center">
                  <span className="mr-2" id={`pass-${phone.id}`}>
                    {phone.password.replace(phone.password.substring(0, (phone.password.length - 3)), "*".repeat(phone.password.length - 3))}
                  </span>
                  <span
                    className="text-gray-500"
                    onClick={e => togglePassword(e, phone.id, phone.password)}
                  >
                    <svg className="w-4 h-4 inline-block -mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                  </span>
                </td> */}
                <td className="py-3 text-center">{format(parseISO(moment(phone.user.createdDate).format()), "dd/MM/yy HH:m:ss")}</td>
                <td className="py-3 text-center">
                  <span className={`w-auto bg-${phone.enabled ? "green" : "red"}-400 text-white rounded px-3 py-1`}>
                    {phone.enabled ? "Active" : "Disabled"}
                  </span>
                </td>
                {/* <td className="pr-10 py-3 text-center">
                  <button
                    onClick={e => toggleEditPhoneForm(e, phone)}
                    className="w-auto h-auto bg-purple-500 rounded float-right text-white p-2"
                    disabled
                  >
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </button>
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PhonesIndex;
