import { NavLink } from "react-router-dom";
import { formatDistanceToNow, parseISO } from "date-fns";
import moment from 'moment';
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
} from "@heroicons/react/outline";

const CallLogIndex = ({ calls, toggleExportForm }) => {
  return (
    <div className="bg-blue-50">
      <div className="w-full flex flex-row border-b-2">
        <div className="flex-none w-5/6 border max-h-screen overflow-scroll">

        </div>
        <div className="w-1/6 flex-none align-items-right border max-h-screen overflow-scroll">
          <button
            className="button h-8 bg-indigo-500 rounded text-white hover:bg-indigo-800 my-2 ml-2 p-1"
            type="button"
            onClick={e => toggleExportForm(e)}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
          </button>
        </div>
      </div>
      {
        calls.length < 0 ? <p className="z-50 text-lg text-black-400 mt-20">No calls at the moment</p> :
        calls.map((call) => (
          call === null ? null : <Call key={call.publicId} {...call} />
        ))
      }
    </div>
  );
};

const Call = ({ publicId, direction, callerNumber, createdDate }) => {

  return (
    <NavLink
      to={`/p/call/${publicId}`}
      className="flex p-5 hover:bg-white hover:border-transparent hover:shadow-xl cursor-pointer rounded-sm"
    >
      <div className="flex flex-row items-center w-full">
        <Icon callDirection={direction} />
        <div className="ml-3 leading-3">
          <span className="text-gray-800 flex-grow min-w-3/4 font-bold">{callerNumber}</span>
          <br />
          <span className="text-gray-400 text-sm">{direction.toLowerCase()}</span>
          {/* <Desc callState={callSessionState} /> */}
        </div>
        <div className="text-gray-400  text-xs text-right  flex-grow">
          {formatDistanceToNow(parseISO(moment(createdDate).format()), { addSuffix: false })}
        </div>
      </div>
    </NavLink>
  );
};

const Icon = ({ callDirection }) => {

  switch (callDirection.toLowerCase()) {
    case "inbound":
      return <PhoneIncomingIcon className="h-4 w-4 text-green-700" />;
      // eslint-disable-next-line no-unreachable
      break;
    case "outbound":
      return <PhoneOutgoingIcon className="h-4 w-4 text-blue-600" />;
      // eslint-disable-next-line no-unreachable
      break;
    default:
      return <PhoneIcon className="h-4 w-4 text-gray-400" />;
      // eslint-disable-next-line no-unreachable
      break;
  }

};

// const Desc = ({ callState }) => {
//   switch (callState.toLowerCase()) {
//     case "missed":
//       return <span className="text-red-400 text-sm">Missed Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//     case "disconnected":
//       return <span className="text-gray-400 text-sm">Disconnected Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//     case "hangup":
//       return <span className="text-gray-400 text-sm">Hangup Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//     case "connected":
//       return <span className="text-gray-400 text-sm">Connected Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//     case "answered":
//       return <span className="text-gray-400 text-sm">Answered Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//     default:
//       return <span className="text-gray-400 text-sm">Misc Call</span>;
//       // eslint-disable-next-line no-unreachable
//       break;
//   }
// };

export default CallLogIndex;
