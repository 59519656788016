import React, { useState, useEffect } from 'react';
import Header from "../../common/ui/Header";
import IVRFlow from "../../common/ivr/IVRFlow";
import IVRComponents from "../../common/ivr/IVRComponents";
import { useQuery, useLazyQuery, useMutation } from "react-query";
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from "formik";
import { useAlert } from 'react-alert';
import { v4 as uuidv4 } from 'uuid';

import getIVR from "../../queries/ivr";
import addIVR from "../../mutations/AddIvr";
import initialElements from '../../common/ivr/initial-elements';

const IVRIndex = () => {
  const alert = useAlert();
  const user = useSelector(state => state.session.user);
  const [requstMethod, setRequestMethod] = useState("post");
  const [elCount, setElCount] = useState(0);
  const [elements, setElements] = useState([]);
  const [selectedElement, setSelectedElement] = useState({ id: "", description: "", type: "", audio_url: "", dtmf_digits: 1, color: "", selected: true, allow_skip: false, repeat_msg: 0 });
  const TYPE_COLORS = {
    agent: "white",
    audio: "green-400",
    unavailable: "indigo-400",
    beyond: "red-400"
  }
  const IVR_TYPE = {
    "PLAY_AUDIO": "audio",
    "SPEAK_TO_AGENT": "agent",
    "BEYOND_WORKING_HOURS": "beyond",
    "NO_AVAILABLE_AGENT": "unavailable"
  };
  const IVR_TYPE_REMOTE = {
    "audio": "PLAY_AUDIO",
    "agent": "SPEAK_TO_AGENT",
    "beyond": "BEYOND_WORKING_HOURS",
    "unavailable": "NO_AVAILABLE_AGENT"
  };

  const loadIvr = (ivrs) => {
    if (ivrs.length > 0) {
      let ivr_options = [];
      ivrs.map((ivr, i) => {
        ivr_options.push({
          id: "node-" + String(ivr.id),
          type: "ivr_node",
          position: {
            x: (100 + (i * 15)),
            y: (60 + (i * 30))
          },
          data: {
            ...ivr,
            id: "node-" + String(ivr.id),
            publicId: ivr.publicId,
            defaultId: ivr.id,
            description: ivr.description,
            type: IVR_TYPE[ivr.type],
            audio_url: ivr.audioUrl,
            dtmf_digits: String(parseInt(ivr.dtmfDigits)) === "NaN" ? 1 : parseInt(ivr.dtmfDigits),
            color: TYPE_COLORS[IVR_TYPE[ivr.type]],
            selected: (i === 0),
            allow_skip: false,
            repeat_msg: 0
          }
        });
        if (!!ivr.parent) {
          ivr_options.push({
            id: "edge-" + String(ivr.id),
            source: "node-" + String(ivr.parent.id),
            target: "node-" + String(ivr.id),
            type: 'smooth-step',
            label: "#: " + String(ivr.dtmfDigits),
          });
        }
        return ivr;
      });
      setElements(ivr_options);
      setElCount(ivr_options.length);
    }
  };

  const addElement = (data, type) => {
    setSelectedElement(data);
    switch(type){
      case "ivr_node":
        elements.push({
          id: data.id,
          type: type,
          position: {
            x: Math.floor(Math.random() * 100),
            y: Math.floor(Math.random() * 100)
          },
          data: data,
        });
        break;
      case "edge":
        elements.push(data);
        break;
      default:
        alert.error("Unknown element type");
        break;
    }
    setElements(elements);
  };

  const addNode = (type) => {
    // e.preventDefault();
    const count = elCount + 1;
    setElCount(count);
    const node_id = String("node-" + String(elCount));
    switch (type) {
      case "agent":
        addElement({ id: node_id, description: "Speak to an agent", type: "agent", audio_url: "", dtmf_digits: 1, color: TYPE_COLORS.agent, selected: true, allow_skip: false, repeat_msg: 0, }, "ivr_node");
        break;
      case "audio":
        addElement({ id: node_id, description: "Attach an audio file", type: "audio", audio_url: "", dtmf_digits: 1, color: TYPE_COLORS.audio, selected: true, allow_skip: false, repeat_msg: 0, }, "ivr_node");
        break;
      case "unavailable":
        addElement({ id: node_id, description: "All our agents are occupied", type: "unavailable", audio_url: "", dtmf_digits: 1, color: TYPE_COLORS.unavailable, selected: true, allow_skip: false, repeat_msg: 0, }, "ivr_node");
        break;
      case "beyond":
        addElement({ id: node_id, description: "Beyond working hours", type: "beyond", audio_url: "", dtmf_digits: 1, color: TYPE_COLORS.beyond, selected: true, allow_skip: false, repeat_msg: 0, }, "ivr_node");
        break;
      default:
        addElement({ id: node_id, description: "Attach an audio file", type: "audio", audio_url: "", dtmf_digits: 1, color: TYPE_COLORS.audio, selected: true, allow_skip: false, repeat_msg: 0, }, "ivr_node");
        break;
    }
  };

  const uploadIVRMenu = () => {
    alert.success('Are you sure you want upload this IVR?', {
      timeout: 15000,
      action: () => {
        uploadIVR(0, []);
      }
    });
  };

  const doAddIVR = useMutation(payload => addIVR(payload), {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      if(data.data){
        const prevElement = elements.filter(el => el.type === 'ivr_node').sort((a, b) => a.id > b.id)[variables.params.uploadCount];
        let uploaded = JSON.parse(JSON.stringify(variables.params.uploaded));
        uploaded.push({...data.data, data: prevElement.data});
        uploadIVR(variables.params.uploadCount + 1, uploaded);
      }
    },
  });

  const uploadIVR = (uploadCount, uploaded) => {
    const nodeElements = elements.filter(el => el.type === 'ivr_node').sort((a, b) => a.id > b.id);
    if (uploadCount >= nodeElements.length){
      alert.success("IVR uploaded successfuly");
      refetchIVR();
      return;
    }

    let parent = null;
    const currentElement = nodeElements[uploadCount];
    console.log("Uploaded: ", uploaded);
    uploaded.map(uploadedIVR => {
      elements.filter(el => el.type === 'smooth-step').map(edge => {
        if (edge.source == uploadedIVR.data.id && edge.target == currentElement.data.id){
          parent = {
            id: uploadedIVR.id,
            publicId: uploadedIVR?.publicId,
            level: uploadedIVR?.level,
            version: 0
          };
        }
        return edge;
      });
      return uploadedIVR;
    });
    let payload = {
      description: currentElement.data.description,
      audioUrl: currentElement.data.audio_url,
      type: IVR_TYPE_REMOTE[currentElement.data.type],
      dtmfDigits: parseInt(currentElement.data.dtmf_digits),
      parent: parent,
    };
    if (requstMethod === "patch") payload = {
      ...payload, 
      id: currentElement?.data?.defaultId,
      publicId: currentElement?.data?.publicId,
      version: currentElement?.data?.version,
    }
    doAddIVR.mutate({
      payload: payload, 
      params: {
        method: requstMethod,
        token: user.token,
        publicId: requstMethod === "post" ? null : currentElement.data.publicId,
        uploadCount: uploadCount,
        uploaded: uploaded
      }
    });
  };

  const { isLoading: loading_ivr, data: ivrData, refetch: refetchIVR } = useQuery(['get_ivr', {
    params: {token: user.token}, 
    payload: {}
  }], getIVR);

  useEffect(() => {
    if (ivrData?.data?.content.length > 1) {
      setRequestMethod("patch");
      loadIvr(ivrData.data.content);
    } else {
      setElements(initialElements);
      setElCount(initialElements.length);
    }
  }, [loading_ivr]);

  useEffect(() => {
    setElements((els) =>
      els.map((el) => {
        if (el.data) el.data.selected = false;

        if (el.data && el.data.id === selectedElement.id) {
          selectedElement.selected = true;
          el.data = selectedElement;
        }

        if (el.target !== undefined && el.target === selectedElement.id)
          el.label = "#: " + String(selectedElement.dtmf_digits);

        return el;
      })
    );
  }, [selectedElement]);

  return (
    <div>
      <Header title="IVR" subtitle="Interactive Voice Recordings" />
      <div className="w-full h-auto relative bg-blue-50 mt-20">
        <div className="w-auto">
          <h2 className="pl-12 pt-4 pb-3 capitalize text-gray-400 border-b">
            IVR
          </h2>
        </div>
        <div className="w-full h-screen absolute left-0 top-8 flex py-4 -pb-12 divide-x-2 divide-grey-50">
          <div className="w-1/5 h-5/6 flex-none bg-white py-5">
            <h3 className="text-sm text-gray-400 border-b pl-5">Components</h3>
            <div className="divide-x-2 divide-grey-50">
              <IVRComponents addNode={addNode} />
            </div>
          </div>
          <div className="w-3/5 h-5/6 flex-none bg-gray-100 py-5">
            <h3 className="text-sm text-gray-400 border-b pl-5">Playground</h3>
            <IVRFlow elements={elements} setElements={setElements} addElement={addElement} setSelectedElement={setSelectedElement} />
          </div>
          <div className="w-1/5 h-5/6 flex-none bg-white py-5">
            <h3 className="text-sm text-gray-400 border-b pl-5">Edit Component</h3>
            <div className="max-w-lg">
              <Formik
                initialValues={selectedElement}
                enableReinitialize={true}
                onSubmit={() => {
                  uploadIVRMenu();
                }}
              >
                {formik => (
                  <Form>
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0  mt-4"
                      as="input"
                      name="description"
                      placeholder="Description"
                      disabled={loading_ivr}
                    />
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0  mt-4"
                      as="select"
                      name="type"
                      placeholder="Type"
                      disabled={loading_ivr}
                    >
                      <option value="type">Type</option>
                      <option value="agent">Speak to Agent</option>
                      <option value="audio">Play Audio</option>
                      <option value="unavailable">No available agent</option>
                      <option value="beyond">Beyond working hours</option>
                    </Field>
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0  mt-4"
                      as="input"
                      name="audio_url"
                      placeholder="Audio url"
                      disabled={loading_ivr}
                    />
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0  mt-4"
                      as="input"
                      name="dtmf_digits"
                      placeholder="Dtmf digits"
                      disabled={loading_ivr}
                    />
                    <label className="block text-sm font-medium text-gray-500 mt-4 ml-4" htmlFor="can_bill">Allow user to skip this step?</label>
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0 mt-4"
                      as="select"
                      name="allow_skip"
                      disabled={loading_ivr}
                    >
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </Field>
                    <label className="block text-sm font-medium text-gray-500 mt-4 ml-4" htmlFor="can_bill">Digit to repeat the message  (0 for no repeat)</label>
                    <Field
                      className="block bg-white w-11/12 resize-y rounded text-sm border border-gray-300 mt-4 mx-auto p-2 focus:ring-0 mt-4"
                      as="input"
                      type="number"
                      name="repeat_msg"
                      value={0}
                      placeholder="Repeat message (0 for no repeat)"
                      disabled={loading_ivr}
                    >
                    </Field>
                    <button
                      onClick={e => {
                        e.preventDefault();
                        setSelectedElement({...selectedElement, 
                          description: formik.values.description,
                          type: formik.values.type,
                          audio_url: formik.values.audio_url,
                          dtmf_digits: formik.values.dtmf_digits,
                          color: TYPE_COLORS[formik.values.type],
                          allow_skip: formik.values.allow_skip === "true",
                          repeat_msg: parseInt(formik.values.repeat_msg),
                          selected: true
                        });
                        console.log({...selectedElement, 
                          description: formik.values.description,
                          type: formik.values.type,
                          audio_url: formik.values.audio_url,
                          dtmf_digits: formik.values.dtmf_digits,
                          color: TYPE_COLORS[formik.values.type],
                          allow_skip: formik.values.allow_skip === "true",
                          repeat_msg: parseInt(formik.values.repeat_msg),
                          selected: true
                        });
                        alert.success("Component updated!");
                      }
                      }
                      disabled={loading_ivr}
                      className="button bg-blue-500 rounded p-2 text-white my-4 ml-3 hover:bg-blue-800"
                      type="submit"
                    >
                      Save change
                    </button>
                    <button
                      disabled={loading_ivr}
                      className="button bg-green-500 rounded p-2 text-white my-4 ml-3 hover:bg-green-800"
                      type="submit"
                    >
                      Upload IVR
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IVRIndex;
