import { Link, withRouter } from "react-router-dom"
import { SessionState } from "sip.js";
import { useState, useEffect } from "react";
import {
  PhoneIncomingIcon,
  PhoneIcon,
  XIcon,
  PauseIcon,
  PlayIcon,
  VolumeOffIcon,
  MicrophoneIcon
} from "@heroicons/react/outline";

import incomingAudio from "../../../static/audio/audio2.wav";
import disconnectedAudio from "../../../static/audio/audio3.wav";

const Incoming = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { history, mediaElement, callAudio, answerCall, hangupCall, toggleMute, toggleHold, displayName, received, muted, onHold } = props;
  const receivedClass = received ? { receive: "hidden", decline: "block", pinging: "" } : { receive: "block", decline: "hidden", pinging: "animate-ping" };
  const callState = received ? "Connected" : "Ringing...";

  function cleanupMedia() {
    if(mediaElement) {
      callAudio.src = "";
      mediaElement.srcObject = null;
      callAudio.pause();
      mediaElement.pause();
    }
  }

  const endCall = (e) => {
    e.preventDefault();
    callAudio.src = disconnectedAudio;
    callAudio.volume = 1.0;
    callAudio.play();
    setTimeout(() => {
      cleanupMedia();
      hangupCall();
      if (history.location.pathname.includes('/p/calls/outgoing')) history.goBack();
    }, 2000);
  };

  useEffect(() => {
    cleanupMedia();
  }, [received]);

  useEffect(() => {
    // play incoming call audio
    callAudio.src = incomingAudio;
    callAudio.volume = 1.0;
    callAudio.play();
  }, []);
  

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <span className="flex h-96 w-96 z-0">
        <span className={"relative inline-flex h-full w-full rounded-full bg-blue-400 opacity-75 " + receivedClass.pinging}></span>
      </span>
      <div className="flex flex-row mt-10">
        <PhoneIncomingIcon className="h-4 w-4 text-gray-300 pr-1 mt-1" />{" "}
        <span className="text-gray-500">Incoming call</span>
      </div>
      <h1 className="text-4xl">{displayName}</h1>
      <p className="text-xl">{callState}</p>
      <div className="flex flex-row gap-10 p-5 z-50 mt-10">
        <Link
          to="#"
          className={"w-12 h-12 flex-none rounded-full bg-purple-500 mt-4 hover:pointer-cursor hover:bg-purple-800 " + receivedClass.decline}
          onClick={e => toggleHold(e)}>
          {onHold ? <PlayIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" /> : <PauseIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" />}
        </Link>
        <Link
          to="#"
          className="w-18 h-18 flex-none rounded-full bg-red-500 p-5 hover:pointer-cursor hover:bg-red-800"
          onClick={e => endCall(e)}>
          <XIcon className="h-10 w-10 text-white" />
        </Link>
        <div
          onClick={e => answerCall(e)}
          className={"w-18 h-18 flex-none rounded-full bg-green-500 p-5 hover:pointer-cursor hover:bg-green-800 " + receivedClass.receive}>
          <PhoneIcon className="h-10 w-10 text-white" />
        </div>
        <Link
          to="#"
          className={"w-12 h-12 flex-none rounded-full bg-pink-500 mt-4 hover:pointer-cursor hover:bg-pink-800 " + receivedClass.decline}
          onClick={e => toggleMute(e)}>
          {muted ? <MicrophoneIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" /> : <VolumeOffIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" />}
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Incoming);
