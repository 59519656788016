import { Switch, Redirect, Route } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/reducers/session";
import jwt_decode from 'jwt-decode';

import Login from './Login';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';

const AuthRouter = ({ children }) => {
  const user = useSelector((state) => state.session.user);
  const logoutDispatcher = useDispatch();

  let decoded_token = null;
  if (user && user.token !== '') {
    decoded_token = jwt_decode(user.token);
    var exp = new Date(decoded_token.exp * 1000);
    var now = new Date();
    if (exp.getTime() <= now.getTime()) {
      decoded_token = null;
      logoutDispatcher(logout());
    }
  }

  if (decoded_token !== null) return children;

  return (
    <Switch>
      <Route path="/auth/login" exact component={Login} />
      <Route path="/auth/register" exact component={Register} />
      <Route path="/auth/forgot-password/" exact component={ForgotPassword} />
      <Route path="/auth/reset-password/:token" exact component={ResetPassword} />
      <Redirect to="/auth/login" />
    </Switch>
  );
};

export default AuthRouter;