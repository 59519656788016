import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";
import moment  from "moment";
import {
  ArrowNarrowRightIcon,
  CalendarIcon,
  PhoneIcon,
  PhoneOutgoingIcon,
  PhoneIncomingIcon,
  SwitchHorizontalIcon,
} from "@heroicons/react/outline";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import Notes from "../notes";
import getContact from '../../queries/get_contact';

const CallDetailsIndex = ({ history, call, refetchCall }) => { 
  const user = useSelector(state => state.session.user);
  const [metaData, setMetaData] = useState({
    agentId: call.id,
    callId: call.id,
  });
  
  let re = /^(?:\\+?254)?([0-9]+)$/;
  const testPhone = (phone) => {
    return re.exec(phone) !== null;
  };

  return (
    <div className="p-5 bg-gray-50  min-h-screen">
      <div className="rounded-xl bg-blue-50 p-7 shadow-lg">
        <div className="flex lex-auto flex-row">
          <div className="flex-1 text-align-left">
            <h1 className="text-4xl">{call.callerNumber}</h1>
          </div>
          <div
            onClick={e => history.push("/p/calls/outgoing/" + call.callerNumber)}
            className="flex-none rounded-full shadow-bg bg-green-100 p-3 hover:pointer-cursor hover:bg-green-300"
          >
            <PhoneIcon className="h-5 w-5 text-green-800" />
          </div>
        </div>
      </div>
      <div className="text-gray-400 flex flex-wrap flex-row w-full pt-5 pb-1 gap-5">
        <div className="flex-grow-0 flex gap-2 items-center">
          <PhoneOutgoingIcon className="h-4 w-4 text-gray-300" /> 
          {call.callerNumber}
        </div>
        <div className="flex-grow-0">
          <ArrowNarrowRightIcon className="h-4 w-6" />
        </div>
        <div className="flex-grow flex gap-2 items-center">
          <PhoneIncomingIcon className="h-4 w-4 text-gray-300" />{" "}
          {call.destinationNumber}
        </div>
        <div className="flex-grow-0 flex gap-2 items-center">
          <CalendarIcon className="h-4 w-4 text-gray-300" />{" "}
          {format(parseISO(moment(call.createdDate).format()), "yyyy-MM-dd HH:mm:ss")}
        </div>
        <div className="flex-grow-0 flex gap-2 items-center">
          <SwitchHorizontalIcon className="h-4" />{" "}
          Direction: {call.direction}
        </div>
        <div className="flex-grow-0 flex gap-2 items-center">
          <SwitchHorizontalIcon className="h-4" />{" "}
          State: {call.callSessionState} - {call.status}
        </div>
        <div className="flex-grow-0 flex gap-2 items-center">
          <PhoneIncomingIcon className="h-4 w-4 text-gray-300" />{" "}
          KES {parseFloat(call.amount).toFixed}
        </div>
        <div className="flex-grow-0 flex gap-2 items-center">
          <PhoneIncomingIcon className="h-4 w-4 text-gray-300" />{" "}
          {call.durationInSeconds === null ? "0" : String(call.durationInSeconds)} secs
        </div>
      </div>

      <div className="w-full mt-8">
        <h2 className="font-semibold text-xl text-gray-500 mb-4">Listen to recording</h2>
        {/* {loading ? <p className="text-sm ml-12 mb-2">Loading audio...</p> : null} */}
        <div className="flex flex-row gap-2">
          <span className="w-14 h-14 flex-none rounded-full bg-blue-500 text-center text-white mt-4 mr-2">
            <svg className="w-6 h-6 mt-4 ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
            </svg>
          </span>
          <AudioPlayer
            autoPlay={false}
            loop={false}
            showJumpControls={false}
            src={call.recordingUrl ? call.recordingUrl : ""}
            onPlay={e => console.log("onPlay")}
            className="rounded"
          />
        </div>
      </div>

      <div className="w-full mt-8">
        <Notes notes={call.notes} metaData={metaData} refetchCall={refetchCall}/>
      </div>
    </div>
  );
};

export default withRouter(CallDetailsIndex);
