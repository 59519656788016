import { useQuery, useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';
import { v4 as uuidv4 } from 'uuid';

import addMember from "../../mutations/AddMember";
import getTitles from "../../queries/titles";
import getRoles from "../../queries/roles";
import getAgents from "../../queries/agents";
import { useState } from "react";

const AddMember = ({ showAddMemberClass, toggleAddMemberForm, refetchMembers }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const [isAgent, setIsAgent] = useState(false);
  const isAgentClass = isAgent ? "visible" : "hidden";
  const className = `add-member-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddMemberClass}`;

  const { data: titles } = useQuery(['get_titles', {
    params: {token: user.token}, 
    payload: {}
  }], getTitles);

  const { data: roles } = useQuery(['get_roles', {
    params: {token: user.token}, 
    payload: {}
  }], getRoles);

  const { isLoading, data: agents } = useQuery(['get_agents', {
    params: {token: user.token}, 
    payload: {}
  }], getAgents);

  const doAddMember = useMutation(payload => addMember(payload), {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`);
    },
    onSuccess: (data, variables, context) => {
      alert.success("User added successfully!");
      refetchMembers();
      toggleAddMemberForm(null);
    },
  });

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/3 -ml-4 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Add User
        </h1>
        <button
          type="button"
          onClick={e => toggleAddMemberForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            role: ""
          }}

          onSubmit={({ title, firstname, lastname, gender, email, phone, role, agent }, { resetForm }) => {
            if (!role || role === "") {
              alert.error("Please select a role!");
              return;
            } else if (!gender || gender === "") {
              alert.error("Please select a gender!");
              return;
            }
            const selectedTitle = titles.data.content.filter(r => r.id == title)[0];
            const selectedRole = roles.data.content.filter(r => r.id == role)[0];
            const selectedAgent = agents.data.content.filter(r => r.id == agent)[0];
            doAddMember.mutate({
              "user": {
                "title": selectedTitle,
                "firstName": firstname,
                "surname": lastname,
                "username": email,
                "phoneNumber": phone,
                "gender": gender,
                "password": "swiftt",
                "userType":"SYSTEM_USER"
              },
              "roles": [
                {
                  "name": selectedRole.name,
                  "publicId": selectedRole.publicId,
                  "version": 1
                }
              ],
              "agent": isAgent ? {
                "sipId": selectedAgent.sipId,
                "phoneNumber": selectedAgent.phoneNumber
              } : null,
              "agentProfile": true
            });
            resetForm({
              name: "",
              email: "",
              role: ""
            });
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="select"
                  name="title"
                  disabled={isLoading}
                  required
                >
                  <option value="">Select their title</option>
                  {!titles ? null : (titles.data.content.map((title) => {
                    return <option key={title.id} value={title.id}>{title.name}</option>;
                  }))}
                </Field>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="firstname"
                  placeholder="Enter their first name"
                  disabled={isLoading}
                  required
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="lastname"
                  placeholder="Enter their last name"
                  disabled={isLoading}
                  required
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="email"
                  placeholder="Enter their email"
                  disabled={isLoading}
                  required
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="select"
                  name="gender"
                  placeholder="Enter their first name"
                  disabled={isLoading}
                  required
                >
                  <option value="">Select their gender</option>
                  <option value={"MALE"}>Male</option>
                  <option value={"FEMALE"}>Female</option>
                  <option value={"OTHER"}>Other</option>
                </Field>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="phone"
                  placeholder="Enter their phone number"
                  disabled={isLoading}
                  required
                />
                <Field
                  className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="select"
                  name="role"
                  disabled={isLoading}
                  required
                >
                  <option value="">Select their role</option>
                  {!roles ? null : (roles.data.content.map((role) => {
                    return <option key={role.id} value={role.id}>{role.name}</option>;
                  }))}
                </Field> 
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0 mt-4 pl-4"
                  as="checkbox" 
                  name="isAgent"
                  placeholder="Enter their phone number"
                  /* onChange={e => {
                    e.preventDefault();
                    console.log(e.target.value);
                  }} */
                  disabled={isLoading}
                />
                <label className="inline-block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="isAgent">Is Agent?</label>
                <Field
                  className={"block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0 mr-2 mt-4 pl-4 " + isAgentClass}
                  as="select"
                  name="agent"
                  disabled={isLoading}
                  required
                >
                  <option value="">Select their agent</option>
                  {!agents ? null : (agents.data.content.map((agent) => {
                    return <option key={agent.id} value={agent.id}>{agent.phoneNumber}</option>;
                  }))}
                </Field>
                <button
                  disabled={isLoading}
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Add</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default AddMember;
