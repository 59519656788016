import { useQuery } from "react-query";
import { useEffect, useState } from "react";

import AddRole from "./AddRole";
import EditRole from "./EditRole";

const RolesTab = ({ roles, refetchRoles }) => {
  const [selectedRole, setSelectedRole] = useState({ id: "" });
  const [showAddRole, setShowAddRole] = useState({ show: false });
  const [showEditRole, setShowEditRole] = useState({ show: false });
  const showEditRoleClass = showEditRole.show === true ? "visible" : "invisible";
  const showAddRoleClass = showAddRole.show === true ? "visible" : "invisible";

  const toggleEditRoleForm = (e, role = { id: "" }) => {
    if (e !== null) e.preventDefault();
    setSelectedRole(role);
    setShowEditRole({
      show: !showEditRole.show
    });
  };

  const toggleAddRoleForm = (e) => {
    if (e !== null) e.preventDefault();
    if (showAddRole.show) refetchRoles();
    setShowAddRole({
      show: !showAddRole.show
    });
  };

  return (
    <div className="p-4">      
      <h3 className="text-sm font-medium text-gray-500 uppercase">Roles</h3>
      <button
        onClick={e => toggleAddRoleForm(e)}
        className="w-auto h-auto bg-purple-500 rounded float-right text-white px-3 py-1 mr-10">
        <svg className="w-6 h-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
        </svg>
        Add Role
      </button>
      <table className="w-4/5 table divide-y divide-gray-200 bg-white shadow-xl mt-10">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Name</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Description</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">View</th> */}
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-right">Edit</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => (
            <tr key={role.id} className="hover:bg-blue-100 cursor-pointer">
              <td className="px-6 py-3 text-left">{role.name}</td>
              <td className="px-6 py-3 text-left">{role.description}</td>
              {/* <td className="px-6 py-3 text-right">
                <button
                  onClick={e => toggleShowPermissions(e, role)}
                  className="shadow rounded text-white bg-green-500 p-1 hover:text-white-600 hover:bg-green-600"
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                  </svg>
                </button>
              </td> */}
              <td className="px-6 py-3 text-right">
                <button
                  onClick={e => toggleEditRoleForm(e, role)}
                  className="shadow rounded text-white bg-purple-500 p-1 hover:text-white-600 hover:bg-purple-600"
                  disabled
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AddRole showAddRoleClass={showAddRoleClass} toggleAddRoleForm={toggleAddRoleForm} />
      {
        selectedRole === null ?
          null :
          <EditRole role={selectedRole} showEditRoleClass={showEditRoleClass} toggleEditRoleForm={toggleEditRoleForm} />
      }
    </div>
  );
};

export default RolesTab;