import { Switch, Redirect, Route } from 'react-router-dom'

import Pages from './components/pages'

function App() {

  return (
    <Switch>
      <Route path="/p" component={Pages} />
      <Redirect to="/p" />
    </Switch>
  );
}

export default App;
