import axios from '../utils/axios';

function getTransactions(){
  const payload = {};
  return new Promise((resolve, reject) => {
    axios
      .get('/ivr', { params: payload })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default getTransactions;
