import ReactFlow, {
  removeElements,
  MiniMap,
  Controls,
  Background,
  Handle
} from 'react-flow-renderer';

const IVRNode = ({ data }) => {
  const selected_class = data.selected ? "shadow-lg ring-1 ring-current " : "shadow";
  return (
    <div className={"w-auto rounded " + selected_class + " px-2 bg-" + data.color}>
      <Handle
        type="target"
        position="top"
        id={"th-" + data.id}
        style={{ width: '8px', height: '8px', "borderRadius": '50%', background: '#2d2d30' }}
      />
      <div className="py-2">
        <h3 className="text-base text-center text-black px-2 capitalize">{data.type}</h3>
        <p className="text-xs text-center text-black px-2">{data.description}</p>
      </div>
      <Handle
        type="source"
        position="bottom"
        id={"bh-" + data.id}
        style={{ width: '8px', height: '8px', "borderRadius": '50%', background: '#2d2d30' }}
      />
    </div>
  );
}

const nodeTypes = {
  ivr_node: IVRNode
};

const IVRFlow = ({ elements, setElements, addElement, setSelectedElement }) => {
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onNodeDragStop = (event, node) =>
    setElements((els) => {
      els.map((el) => {
        if (el.id === node.id) return node;
        return el;
      });
      return els;
    });

  const onConnect = (params) => {
    addElement({
      ...params,
      type: 'smooth-step',
      id: "e" + params.source.split("-")[1] + "-" + params.target.split("-")[1],
      label: "#: " + String(elements.filter(el => el.id === params.target)[0].data.dtmf_digits),
    }, "edge");
  }

  const onElementClick = (event, el) => {
    if (el.data) {
      el.data.selected = true;
      setSelectedElement(el.data);
    }
  };

  return (
    <ReactFlow
      nodeTypes={nodeTypes}
      elements={elements}
      onElementsRemove={onElementsRemove}
      onConnect={onConnect}
      onElementClick={onElementClick}
      snapToGrid={true}
      onNodeDragStop={onNodeDragStop}
      snapGrid={[15, 15]}
    >
      <MiniMap
        nodeStrokeColor={(n) => {
          // if (n.style?.background) return n.style.background;
          // if (n.type === 'input') return '#0041d0';
          // if (n.type === 'output') return '#ff0072';
          // if (n.type === 'default') return '#1a192b';

          return '#000';
        }}
        nodeColor={(n) => {
          if (n.style?.background) return n.style.background;

          return '#fff';
        }}
        nodeBorderRadius={2}
      />
      <Controls />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  );
};

export default IVRFlow;