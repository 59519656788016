import { useQuery } from "react-query";
import { UserIcon } from "@heroicons/react/solid";
import { withRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
// import StyledPaginate from '../ui/StyledPaginate';

import getNotes from "../../queries/contact_notes";
import getCallLogs from "../../queries/contact_call_logs";

const ContactDetail = ({ history, currentContact }) => {
  const user = useSelector((state) => state.session.user);
  const [activeTab, setActiveTab] = useState({ details: "border-b-2 border-purple-500", logs: "border-b-0", notes: "border-b-0" });
  const [currentTab, setCurrentTab] = useState({ details: "visible", logs: "invisible", notes: "invisible" });
  const [currentLogs, setCurrentLogs] = useState([]);
  const [currentNotes, setCurrentNotes] = useState([]);
  // const [currentItems, setCurrentItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [activeItems, setActiveItems] = useState([]);
  // const [pageCount, setPageCount] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [itemOffset, setItemOffset] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(5);

  const { data: logs } = useQuery(['get_call_logs', {
    params: {token: user.token, phone: currentContact.phone},
    payload: {}
  }], getCallLogs);

  const { data: notes } = useQuery(['get_notes', {
    params: {token: user.token, phone: currentContact.phone},
    payload: {}
  }], getNotes);

  const switchTab = (e, tab) => {
    switch (tab) {
      case "logs":
        setActiveTab({
          details: "border-b-0",
          logs: "border-b-2 border-purple-500",
          notes: "border-b-0"
        });

        setCurrentTab({
          details: "invisible",
          logs: "visible",
          notes: "invisible"
        });
        break;

      case "notes":
        setActiveTab({
          details: "border-b-0",
          logs: "border-0",
          notes: "border-b-2 border-purple-500"
        });

        setCurrentTab({
          details: "invisible",
          logs: "invisible",
          notes: "visible"
        });
        break;

      default:
        setActiveTab({
          details: "border-b-2 border-purple-500",
          logs: "border-b-0",
          notes: "border-b-0"
        });

        setCurrentTab({
          details: "visible",
          logs: "invisible",
          notes: "invisible"
        });
        break;
    }
  };

  // useEffect(() => {
  //   // Fetch items from another resources.
  //   const endOffset = itemOffset + itemsPerPage;
  //   if (activeItems.length > 0) {
  //     console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  //     console.log("All Items: ", activeItems);
  //     setCurrentItems(activeItems.slice(itemOffset, endOffset));
  //     console.log("Loaded Items: ", currentItems);
  //     setPageCount(Math.ceil(activeItems.length / itemsPerPage));
  //   }
  // }, [activeItems, itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  // const handlePageClick = (event) => {
  //   const newOffset = (event.selected * itemsPerPage) % activeItems.length;
  //   console.log(
  //     `User requested page number ${event.selected}, which is offset ${newOffset}`
  //   );
  //   setItemOffset(newOffset);
  // };

  return (
    <div className="w-full h-4/6 bg-white">
      <h1 className="pt-3 pl-5">Contact Details</h1>
      <div className="header w-auto flex flex-wrap p-5 pl-10 mt-5 bg-white">
        <div className="w-20 flex-none sm:w-32">
          <UserIcon className="rounded-full h-20 w-20 p-3 flex bg-gray-300" />
        </div>
        <div className="flex-auto">
          <h2 className="font-serif font-bold text-4xl mt-1">{currentContact.name}</h2>
          <p className="font-serif font-normal text-lg mt-2">{currentContact.phone}</p>
        </div>
        <div className="flex flex-auto justify-end">
          <button
            className="button flex-none w-14 h-14 bg-green-500 rounded-full text-white hover:bg-green-800 mt-2 mr-6 p-1"
            type="button"
            onClick={e => { e.preventDefault(); history.push("/p/calls/outgoing/" + currentContact.phone) }}
          >
            <svg className="w-6 h-6 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
            </svg>
          </button>
          {/* <button
            className="button flex-none w-14 h-14 bg-blue-500 rounded-full text-white hover:bg-blue-800 mt-2 mx-2 p-1"
            type="button"
          >
            <svg className="w-6 h-6 ml-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
            </svg>
          </button> */}
        </div>
      </div>

      <div className="w-full h-full bg-white px-6 py-4">
        <nav className="flex flex-col sm:flex-row">
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.details}
            onClick={e => switchTab(e, "details")}
          >Details</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.logs}
            onClick={e => switchTab(e, "logs")}
          >Call logs</button>
          <button
            className={"text-gray-600 py-4 px-6 block hover:text-purple-500 focus:outline-none text-purple-500 font-medium " + activeTab.notes}
            onClick={e => switchTab(e, "notes")}
          >Notes</button>
        </nav>

        <div className="w-full h-5/6 items-center justify-center bg-white">
          <div className="w-full h-full relative container bg-gray-100 overflow-y-scroll">
            <div className={"w-11/12 h-full absolute top-2 inset-x-2 flex flex-col rounded p-4 " + currentTab.details}>
              {currentContact?.name === null ? null : <ContactDetailItem detail={"Name: " + currentContact.name} />}
              {currentContact?.phone === null ? null : <ContactDetailItem detail={"Phone: " + currentContact.phone} />}
              {currentContact?.company === null ? null : <ContactDetailItem detail={"Company: " + currentContact.company} />}
              {currentContact?.address === null ? null : <ContactDetailItem detail={"Address: " + currentContact.address} />}
            </div>

            <div className={"w-11/12 h-full absolute top-2 inset-x-2 flex flex-col rounded p-4 " + currentTab.logs}>
              {
                !!logs?.data?.content ? (
                  logs.data.content .map(log => (
                    <ContactDetailItem key={log.id} detail={"From: " + log.callerNumber + ", To: " + log.destinationNumber + ", Direction: " + log.direction} />
                  ))
                ) : null
              }
            </div>

            <div className={"w-11/12 h-full absolute top-2 inset-x-2 flex flex-col rounded p-4 " + currentTab.notes}>
              {
                !!notes?.data?.content ? (
                  notes.data.content .map(note => (
                    <ContactDetailItem key={note.id} detail={note.note} />
                  ))
                 ) : null
              }
            </div>
          </div>
          {/* <StyledPaginate
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel="< previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
            renderOnZeroPageCount={null}
          /> */}
        </div>
      </div>
    </div>
  )
}

const ContactDetailItem = (detail) => {
  return (
    <div className="w-full bg-white rounded border-l-2 border-purple-500 p-4 my-1.5">
      {detail.detail}
    </div>
  );
};

export default withRouter(ContactDetail)