// import { useQuery, useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';
import { useState } from "react";

import ADD_PHONE from "../../mutations/AddPhone";
import MEMBERS from "../../queries/members";
import countryList from "../../../store/data/countries";

const AddPhone = ({ showAddPhoneClass, toggleAddPhoneForm }) => {
  const loading = true;
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const [agents, setAgents] = useState([]);
  const className = `add-phone-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddPhoneClass}`;

  // const [addPhone, { loading }] = useMutation(ADD_PHONE, {
  //   onCompleted: (data) => {
  //     alert.success("Request submitted successfully!");
  //     toggleAddPhoneForm(null);
  //     setTimeout(() => {
  //       window.location.assign("/p/phones");
  //     }, 1500);
  //   }
  // });

  // const [getAgents] = useLazyQuery(MEMBERS, {
  //   onCompleted: (data) => {
  //     // console.log(data);
  //     if (data?.call_center_by_pk?.party?.partyRelationshipsByToPartyId) {
  //       setAgents(data.call_center_by_pk.party.partyRelationshipsByToPartyId);
  //       document.getElementById("sip_agent").style.display = "block";
  //     }
  //   },
  // });

  // const showAgentInput = (e) => {
  //   e.preventDefault();
  //   if (e.target.value === "sip")
  //     getAgents({
  //       variables: {
  //         call_center_id: user.party.callCenterId
  //       }
  //     });
  //   else document.getElementById("sip_agent").style.display = "none";
  // };

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Request Phone
        </h1>
        <button
          type="button"
          onClick={e => toggleAddPhoneForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>

        <Formik
          initialValues={{
            name: "",
            email: "",
            country: "",
            comments: ""
          }}

          onSubmit={({ name, email, country, sip_agent }, { resetForm }) => {
            const phone_type = document.getElementById("phone_type").value;
            if (!phone_type || phone_type === "") {
              alert.error("Please select a phone type!");
              return;
            }
            // addPhone({
            //   variables: {
            //     "request": {
            //       "name": name,
            //       "email": email,
            //       "phone_type": phone_type,
            //       "country": country,
            //       "agent": sip_agent,
            //       "call_center_id": user.party.callCenterId
            //     }
            //   }
            // });
            resetForm({
              name: "",
              email: "",
              country: "",
              sip_agent: "",
            });
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="name"
                  placeholder="Enter the agent/business name"
                  disabled={loading}
                  required
                />
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="email"
                  placeholder="Enter the agent/business email"
                  disabled={loading}
                  required
                />
                <select
                  id="phone_type"
                  name="phone_type"
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  /* onChange={e => showAgentInput(e)} */
                >
                  <option value="">Select a phone type</option>
                  <option value="regular">Regular Phone number</option>
                  <option value="sip">SIP Phone</option>
                </select>
                <Field
                  id="sip_agent"
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4 hidden"
                  as="select"
                  name="sip_agent"
                  disabled={loading}
                >
                  <option key="opt-0" value="">Select an agent for the sip line (optional)</option>
                  {
                    agents.length < 1 ? null : agents.map(agent => (
                      <option key={agent.party.id} value={agent.party.id}>{agent.party.name} ({agent.relation.name})</option>
                    ))
                  }
                </Field>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="select"
                  name="country"
                  placeholder="Enter the country"
                  disabled={loading}
                  required
                >
                  <option key="opt-0" value="">Select a country</option>
                  {
                    countryList.map((c, i) => (
                      <option key={`country-${i}`} value={c}>{c}</option>
                    ))
                  }
                </Field>
                <button
                  disabled={loading}
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Add</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default AddPhone;
