import React from 'react';
import { useState } from "react";
import { Formik, Form, Field } from "formik";

const AutoCompleteInput = ({ className, loading, placeholder, perfomFilter, setSuggestion }) => {
  const [filtered, setFiltered] = useState([]);
  const [showResults, setShowResults] = useState(false);
  let showResultsClass = showResults ? "block" : "hidden";

  const showSuggestions = (search) => {
    perfomFilter(search, setFiltered);
    setShowResults(true);
    setTimeout(() => {
      if (filtered.length < 1 && search.length < 1) {
        setShowResults(false);
      }
    }, 5000);
  };

  return (
    <div className={`h-auto flex-auto relative my-5 pl-3 pt-1 ${className}`}>
      <Formik
        initialValues={{
          search: "",
        }}
        onSubmit={({ search }) => {
          showSuggestions(search);
        }}
      >
        {(props => (
          <Form className="w-full h-auto m-auto flex">
            <Field
              className="flex-auto w-10/12 h-auto p-2 mr-2 rounded text-sm border border-gray-300 focus:ring-0"
              placeholder={placeholder}
              as="input"
              name="search"
              onChange={e => { e.preventDefault(); props.handleChange(e); showSuggestions(props.values.search) }} />
            <button
              disabled={loading}
              className="w-auto h-8 button flex-none h-8 bg-blue-500 p-1 mt-1 rounded text-white hover:bg-blue-800"
              type="submit"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </button>
          </Form>)
        )}
      </Formik>
      <div className={"w-11/12 m-auto mr-11 h-auto bg-white shadow-lg rounded-b-md absolute top-full left-0 right-0 px-5 py-5 z-50 " + showResultsClass}>
        <h2 className="w-auto text-md border-b-2 border-gray-100 pb-2">Search Results:</h2>
        <button
          onClick={(e) => { e.preventDefault(); setShowResults(false); }}
          disabled={loading}
          className="w-auto h-8 button absolute top-1 right-5 h-8 bg-red-500 p-1 mt-1 rounded text-white hover:bg-red-800"
          type="submit"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {filtered.map((suggestion) => {
          return <Suggestion key={suggestion.id} suggestion={suggestion} setSuggestion={setSuggestion} setShowResults={setShowResults} />;
        })}
      </div>
    </div>
  );
}

const Suggestion = ({ suggestion, setSuggestion, setShowResults }) => {
  return (
    <div
      onClick={() => { setSuggestion(null, suggestion.data); setShowResults(false); }}
      className="w-full h-auto flex-auto relative py-2 cursor-pointer hover:bg-gray-100">
      <p className="w-11/12 m-auto p-2">{suggestion.suggestion}</p>
    </div>
  );
}

export default AutoCompleteInput;