import { withRouter } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, Field, Form } from "formik";
// import { useState } from "react";
// import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import ADD_TEAM from "../../mutations/AddTeam";

const AddTeam = ({ history, organisations, showAddTeamClass, toggleAddTeamForm }) => {
  const alert = useAlert();
  // const user = useSelector((state) => state.session.user);
  const className = `add-team-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showAddTeamClass}`;

  const [addTeam, { loading }] = useMutation(ADD_TEAM, {
    onCompleted: () => {
      toggleAddTeamForm(null);
      alert.success("Call Center Created!", {
        timeout: 2000,
        action: () => {
          history.push("/p/calls");
        }
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const createTeam = (toPartyId, toPartyName, teamName) => {
    alert.error(`Create call center for ${toPartyName}?`, {
      timeout: 10000,
      action: () => {
        addTeam({
          variables: {
            "payload": {
              "name": teamName,
              "to_party_id": toPartyId
            }
          },
        });
      }
    });
  };

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Add Team
        </h1>
        <button
          type="button"
          onClick={e => toggleAddTeamForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <Formik
          initialValues={{
            name: "",
          }}

          onSubmit={({ to_party_id, name }, { resetForm }) => {
            if (!to_party_id || !name) {
              alert.error("Select an organisation to add the call center to!");
              return;
            }
            const to_party_name = organisations.filter(org => org.id === to_party_id)[0].name;
            createTeam(to_party_id, to_party_name, name);
            resetForm({
              name: "",
            });
          }}
        >
          {
            formik => (
              <Form>
                <Field
                  className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="select"
                  name="to_party_id"
                  disabled={loading}
                >
                  <option key="no-party-selected" value="">Select organisation to add call center</option>
                  {organisations === undefined ? null : (organisations.map((org) => {
                    return <option key={org.id} value={org.id}>{org.name}</option>;
                  }))}
                </Field>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  name="name"
                  placeholder="Enter a name for the call center"
                  disabled={loading}
                />
                <button
                  disabled={loading}
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Save</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default withRouter(AddTeam);
