import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
// import jwt_decode from "jwt-decode";
import { useAlert } from 'react-alert';

import { update } from "../../store/reducers/session";
import logo from "../../static/logo.png";
// import GET_EXTENSION from "../queries/get_extension";
import login from "../queries/login";

const Login = ({ history }) => {
  const alert = useAlert();
  const [reattempt, setReattempted] = useState(false);
  const [payload, setPayload] = useState(null);
  const updateDispatch = useDispatch();

  // const [getExtension] = useLazyQuery(GET_EXTENSION, {
  //   onCompleted: (data) => {
  //     // console.log(data);
  //     if (loginToken !== null){
  //       if (data && data.extension_assignment && data.extension_assignment.length > 0) {
  //         const party = jwt_decode(loginToken);
  //         updateDispatch(update({ token: loginToken, party: { ...party, callCenterId: "", extension: data.extension_assignment[0].extension } }));
  //       } else updateDispatch(update({ token: loginToken, party: { ...jwt_decode(loginToken), callCenterId: ""} }));
  //       history.push("/p/teams");
  //     }
  //   }
  // });

  const { isLoading, error, data, refetch } = useQuery(
    [
      'login', 
      {
        params: {},
        payload: payload
      }
    ], 
    login,
    { enabled: false }
  );

  useEffect(() => {
    if (!isLoading && !!data && String(data?.status) === "200") {
      if (data.data.roles.filter(r => r.name == "System Administrator").length > 0) {
        setPayload({...payload, role: data.data.roles.filter(r => r.name == "System Administrator")[0].publicId})
        getAdminToken();
      } else {
        alert.success("Login Successfull!");
        updateDispatch(update({ token: data.data.access_token, user: data.data }));
        history.push("/p/dashboard/");
      }
    } else if (!isLoading && !!error) alert.error(error?.response?.data?.error_description);
  }, [isLoading]);

  useEffect(() => {
    refetch();
  }, [payload]);

  const { isLoading: refetching, error: adminError, data: adminData, refetch: getAdminToken } = useQuery(
    [
      'admin_login', 
      {
        params: {},
        payload: payload
      }
    ], 
    login,
    { enabled: false }
  );

  useEffect(() => {
    if (!refetching && !!adminData && String(adminData?.status) === "200") {
      updateDispatch(update({ token: adminData.data.access_token, adminToken: adminData.data.access_token, user: data.data }));
      alert.success("Login Successfull!");
      history.push("/p/dashboard/");
    } else if (!refetching && !!adminError) alert.error(adminError.response.data.error_description);
  }, [refetching]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
            remember: "",
            remember_me: "",
          }}
          onSubmit={({ email, password }) => {
            setPayload({
              "username": email,
              "password": password,
              "grant_type": "password"
            });
          }}
        >
          <Form className="mt-8 space-y-6" action="#" method="POST">
            <Field type="hidden" name="remember" value="true" />
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <Field
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Field
                  id="remember_me"
                  name="remember_me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              {/* <div className="text-sm">
                <Link
                  to="/auth/forgot-password/"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </Link>
              </div> */}
            </div>

            {/* <div className="text-sm text-center">
              <Link
                to="/auth/register"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Don't have an account? Register Here
              </Link>
            </div> */}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                Sign in
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Login;
