import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { useMutation } from "react-query";
import { useState } from "react";
import { PencilAltIcon } from "@heroicons/react/outline"
import { useAlert } from 'react-alert';

import logo from "../../static/logo.png";
// import ADD_PARTY from "../mutations/AddParty";
import register from "../mutations/Register";

const Register = ({ history }) => {
  const alert = useAlert();
  const [uploadedUser, setUploadedUser] = useState(null);
  const [organizationUploaded, setOrganizationUploaded] = useState(false);
  const organizationUploadedClass = organizationUploaded ? { orgField: "hidden", userField: "block" } : { orgField: "block", userField: "hidden" }

  // const [register, { registering }] = useMutation(REGISTER, {
  //   onCompleted: (data) => {  
  //     if (data && String(data.signup.statusCode) === "200") {
  //       alert.success("Registration Successfull. Login now...");
  //       history.push("/auth/login/");
  //     } else {
  //       alert.error("Registration Failed. Please try again later");
  //     }
  //   }
  // });

  // const [addOrganization, { uploading_org }] = useMutation(ADD_PARTY, {
  //   onCompleted: (data) => {
  //     if (data) {
  //       if (data && uploadedUser) {
  //         register({
  //           variables: {
  //             "user": {
  //               "email": uploadedUser.email,
  //               "name": uploadedUser.name,
  //               "password": uploadedUser.password,
  //               "relationship_id": "7cd25bbc-9075-4aaa-a85a-ccfeca560db9",
  //               "to_party_id": data.insert_party_one.id
  //             }
  //           }
  //         });
  //       }
  //     }
  //   }
  // });

  const doRegister = useMutation(register, {
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`)
    },
    onSuccess: (data, variables, context) => {
      if (data && String(data.statusCode) === "200") {
        alert.success("Registration Successfull. Login now...");
        history.push("/auth/login/");
      } else {
        alert.error("Registration Failed. Please try again later");
      }
    },
  })

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-16 w-auto" src={logo} alt="Workflow" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign up for an account
          </h2>
        </div>
        <Formik
          initialValues={{
            organization_name: "",
            email: "",
            name: "",
            password: "",
          }}
          onSubmit={(values) => {
            if (values.organization_name) {
              setUploadedUser(values);
              doRegister({
                "email": values.email,
                "name": values.name,
                "password": values.password
              });
              // addOrganization({
              //   variables: {
              //     "party": {
              //       "name": values.organization_name,
              //     }
              //   },
              // });
            } else {
              alert.error("Please fill in the organisation name");
            }
          }}
        >
          {
            formik => (
              <Form className="mt-8 space-y-6" action="#" method="POST">
                <div className={"h-auto -space-y-px " + organizationUploadedClass.orgField}>
                  <div>
                    <label htmlFor="organization_name" className="sr-only">
                      Organisation's Name
                    </label>
                    <Field
                      as="input"
                      id="organization_name"
                      name="organization_name"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Enter your Organisation's Name..."
                    />
                  </div><br />
                  <button
                    type="button"
                    onClick={e => { setOrganizationUploaded(!organizationUploaded) }}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    {organizationUploaded ? "Edit organisation name" : "Submit"}
                  </button>
                </div>
                <div className={"h-auto " + organizationUploadedClass.userField}>
                  <div className="h-auto my-4 flex">
                    <p className="flex-auto text-md text-black-400">{"Organization name: " + formik.values.organization_name}</p>
                    <span 
                      className="flex-none w-6 h-6 float-right cursor-pointer mr-1"
                      onClick={(e) => setOrganizationUploaded(!organizationUploaded)}>
                      <PencilAltIcon className="w-6 h-6" />
                    </span>
                  </div>
                  <h2 className="text-sm text-gray-400 my-2">Admin Details</h2>
                  <div>
                    <label htmlFor="name" className="sr-only">
                      Name
                    </label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Name"
                    />
                  </div>
                  <div>
                    <label htmlFor="email-address" className="sr-only">
                      Email address
                    </label>
                    <Field
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Email address"
                    />
                  </div>
                  <div>
                    <label htmlFor="password" className="sr-only">
                      Password
                    </label>
                    <Field
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div className="text-sm text-center">
                  <Link
                    to="/auth/login"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Already have an account? Login Here
                  </Link>
                </div>

                <div className={organizationUploadedClass.userField}>
                  <button
                    type="submit"
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                    Sign Up
                  </button>
                  {/* <p className="text-sm text-md text-gray-400">
                    {
                      uploading_org || registering  ? "Loading" : ""
                    }
                  </p> */}
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default Register;
