import axios from '../utils/axios';

function getProfile({queryKey}){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios
      .get('/profile', { params: body.params })
      .then(response => resolve(response))
      .catch(error => reject(error))
  });
}

export default getProfile;
