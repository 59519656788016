import { formatDistanceToNow, parseISO } from "date-fns";
import moment from "moment";
import {
  PhoneIcon,
  PhoneIncomingIcon,
  PhoneOutgoingIcon,
} from "@heroicons/react/outline";
import { memo } from "react";

const LogsTab = ({ logs }) => {

  return (
    <div className="w-full">
      <div className="w-full text-xl text-gray-500 m-2 my-4 border-b-2 border-bg-grey">
        Recent Calls
      </div>
      <table className="w-full table-fixed divide-y divide-gray-200 bg-white shadow-xl">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-left">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
              </svg>
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
              </svg>
            </th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12 text-left">Caller</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-4/12 text-left">Destination</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">Cost</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-left">State</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-left">Time</th>
          </tr>
        </thead>
        <tbody>
          {
            logs.map(log => {
              if (log !== null) 
                return (<tr key={log.id} className="hover:bg-blue-100 cursor-pointer">
                  <td className="px-6 py-3 text-left">
                    <Icon callDirection={log.direction} />
                  </td>
                  <td className="px-6 py-3 text-left">{log.callerNumber}</td>
                  <td className="px-6 py-3 text-left">{log.destinationNumber}</td>
                  <td className="px-6 py-3 text-left">{parseFloat(log.amount).toFixed(2)}</td>
                  <td className="px-6 py-3 text-left">{log.callSessionState}</td>
                  <td className="px-6 py-3 text-left">{formatDistanceToNow(parseISO(moment(log.createdDate).format()), { addSuffix: false })}</td>
                </tr>)
              return log;
            })
          }
        </tbody>
      </table>
    </div>
  );
};


const Icon = ({ callDirection }) => {

  switch (callDirection.toLowerCase()) {
    case "inbound":
      return <PhoneIncomingIcon className="h-4 w-4 text-green-600" />;
      // eslint-disable-next-line no-unreachable
      break;
    case "outbound":
      return <PhoneOutgoingIcon className="h-4 w-4 text-blue-700" />;
      // eslint-disable-next-line no-unreachable
      break;
    default:
      return <PhoneIcon className="h-4 w-4 text-gray-400" />;
      // eslint-disable-next-line no-unreachable
      break
  }

};

export default memo(LogsTab);