import { useState } from "react";
import { useMutation } from "react-query";
import { Formik, Field, Form } from "formik";
// import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

import UpdateInstitution from "../../mutations/UpdateParty";

const EditParty = ({ showEditPartyClass, toggleEditPartyForm, party }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  // const user = useSelector((state) => state.session.user);
  const className = `edit-org-form w-screen h-screen overlay fixed left-0 top-0 overflow-y-scroll bg-black bg-opacity-50 z-50 ${showEditPartyClass}`;

  const doUpdateInstitution = useMutation(UpdateInstitution, {
    onError: (error, variables, context) => {
      console.log(`Reset Error: ${error}`)
    },
    onSuccess: (data, variables, context) => {
      alert.success("Details Updated Successfully!");
      toggleEditPartyForm(null);
    },
  });

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/2 -ml-80 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Edit Profile
        </h1>
        <button
          type="button"
          onClick={e => toggleEditPartyForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
          <Formik
            initialValues={party}
            onSubmit={(values, { resetForm }) => {
              doUpdateInstitution({
                party_id: { "id": party.id },
                party: values
              });
              resetForm({
                name: "",
                legal_name: "",
                business_tagline: "",
                institution_code: "",
                country: "",
                city: "",
                business_street_address: "",
                business_postal_address: "",
                business_postal_code: "",
                business_email: "",
                default_contact: "",
                business_website: ""
              });
            }}
          >
            {
              formik => (
                <Form >
                  <div className="w-11/12 m-auto py-2">
                    <h3 className="text-sm font-medium text-gray-500 border-b my-2">Basic Details</h3>
                    <Field
                      className="block bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="name"
                      placeholder="Enter your organization's institutional name"
                      disabled={loading}
                      required
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="legal_name"
                      placeholder="Enter your organization's legal name"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_tagline"
                      placeholder="Enter your organization's tag line"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="institution_code"
                      placeholder="Enter your organization's institution code"
                      disabled={loading}
                    />
                  </div>
                  <div className="w-11/12 m-auto py-2">
                    <h3 className="text-sm font-medium text-gray-500 border-b my-2">Address Details</h3>
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="country"
                      placeholder="Enter your organization's country"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="city"
                      placeholder="Enter your organization's city"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_street_address"
                      placeholder="Enter your organization's physical/business street address"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_postal_address"
                      placeholder="Enter your organization's postal address"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_postal_code"
                      placeholder="Enter your organization's postal code"
                      disabled={loading}
                    />
                  </div>
                  <div className="w-11/12 m-auto py-2 border-b">
                    <h3 className="text-sm font-medium text-gray-500 border-b my-2">Basic Details</h3>
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_email"
                      placeholder="Enter your organization's email"
                      disabled={loading}
                      required
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="default_contact"
                      placeholder="Enter your organization's phone number"
                      disabled={loading}
                    />
                    <Field
                      className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                      as="input"
                      name="business_website"
                      placeholder="Enter your organization's business website"
                      disabled={loading}
                    />
                  </div>
                  <button
                    disabled={loading}
                    className="w-auto button bg-green-500 rounded p-1 text-white hover:bg-green-800 ml-12 my-5 p-2"
                    type="submit"
                  >Save changes</button>
                </Form>
              )
            }
          </Formik>
      </div>
    </div>
  );
};

export default EditParty;
