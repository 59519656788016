const BASE_URL = 'https://voice.swifttdial.com';

const API_VERSION = "/api/v1"

const AUTH_TOKEN = localStorage.getItem('token') ? localStorage.getItem('token') : "";

const AUTH_PORT = ":1599";

const ID_PORT = ":6778  ";

const AT_PORT = ":8081";

const AT_API_KEY = '042268cd81757dc24fe8f3f04248d266f5c43756ab88e8c1557e8c1a89439eed';

export { BASE_URL, API_VERSION, AUTH_TOKEN, AUTH_PORT, ID_PORT, AT_PORT, AT_API_KEY };