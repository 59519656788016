import { Formik, Field, Form } from "formik";
import { useSelector } from "react-redux";
import { useAlert } from 'react-alert';

const ExportForm = ({ showExportClass, toggleExportForm, setExportData }) => {
  const alert = useAlert();
  const user = useSelector((state) => state.session.user);
  const className = `add-contact-form w-screen h-screen overlay fixed left-0 top-0 overflow-scroll bg-black bg-opacity-50 z-50 ${showExportClass}`;

  return (
    <div className={className}>
      <div className="w-5/12 h-auto absolute top-56 left-1/3 -ml-4 bg-white rounded-sm shadow p-4">
        <h1 className="w-full font-medium text-center text-xl sm:text-2xl uppercase w-60 text-center p-3">
          Export
        </h1>
        <button
          type="button"
          onClick={e => toggleExportForm(e)}
          className="bg-white absolute -top-4 -right-2 rounded-md shadow p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
        >
          <span className="sr-only">Close menu</span>
          <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <Formik
          initialValues={{
            startDate: "",
            endDate: ""
          }}
          onSubmit={(values, { resetForm }) => {
            setExportData(values);
            resetForm({
              startDate: "",
              endDate: ""
            });
          }}
        >
          {
            formik => (
              <Form>
                <label className="block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="startDate">Start date</label>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  type="date"
                  name="startDate"
                  placeholder="Enter the start date"
                  required
                />
                <label className="block text-sm font-medium text-gray-500 mt-4 mb-2" htmlFor="endDate">End date</label>
                <Field
                  className="flex-auto bg-white w-full h-10 resize-y rounded text-sm border border-gray-300 mt-4 focus:ring-0  mt-4 pl-4"
                  as="input"
                  type="date"
                  name="endDate"
                  placeholder="Enter the end date"
                  required
                />
                <button
                  className="w-24 button block bg-blue-500 rounded p-1 px-2 text-white hover:bg-blue-800 mt-3 p-2"
                  type="submit"
                >Submit</button>
              </Form>
            )
          }
        </Formik>
      </div>
    </div>
  );
};

export default ExportForm;
