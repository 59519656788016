import { BASE_URL, AT_PORT, API_VERSION } from '../utils/config';
import axios from 'axios';

function getCallLogs({ queryKey }){
  const [_, body] = queryKey;
  const config = {
    method: 'get',
    url: BASE_URL + AT_PORT + API_VERSION + '/calls',
    headers: { 
      'Authorization': `Bearer ${body.params.token}`,
    }
  }
  return new Promise((resolve, reject) => {
    axios(config)
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default getCallLogs;
