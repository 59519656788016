import { UserIcon } from "@heroicons/react/solid";

const CommonContactIndex = ({ contacts, changeCurrentContact }) => {
  console.log(contacts);
  return (
    <div className="h-fit bg-blue-50 content-center">
      <div className="conatcts bg-blue-50 w-full">
        {contacts.map((contact) => (
          <Contact key={contact.id} contact={contact} changeCurrentContact={changeCurrentContact} />
        ))}
      </div>
    </div>
  );
};

const Contact = ({ contact, changeCurrentContact }) => {
  return (
    <div
      onClick={e => changeCurrentContact(e, contact)}
      className="flex items-center hover:bg-white hover:border-transparent hover:shadow-xl p-5">
      <div className="text-gray-100 bg-gray-400 rounded-full p-1">
        <UserIcon className="h-4 w-4" />
      </div>
      <div className="flex flex-col pl-4 cursor-pointer rounded-sm">
        <b>{contact.name}</b>
        <small>{contact.phone}</small>
      </div>
    </div>
  );
};

export default CommonContactIndex;
