import React, { useState } from 'react';
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';

import Header from "../../common/ui/Header";
import AddMember from '../../common/members/AddMember';
import EditMember from '../../common/members/EditMember';
import getMembers from '../../queries/members';

const MembersIndex = () => {
  const user = useSelector(state => state.session.user);
  const [currentMember, setCurrentMember] = useState(null);
  const [showAddMember, setShowAddMember] = useState({ show: false });
  const [showEditMember, setShowEditMember] = useState({ show: false });
  const showAddMemberClass = showAddMember.show === true ? "visible" : "invisible";
  const showEditMemberClass = showEditMember.show === true ? "visible" : "invisible";

  const { data: members, refetch: refetchMembers } = useQuery(['get_members', {
    params: {token: user.token}, 
    payload: {}
  }], getMembers);

  const toggleAddMemberForm = (e) => {
    if (e !== null) e.preventDefault();
    setShowAddMember({
      show: !showAddMember.show
    });
  };

  const toggleEditMemberForm = (e, member = null) => {
    if (e !== null) e.preventDefault();
    setCurrentMember(member);
    setShowEditMember({
      show: !showEditMember.show
    });
  };

  return (
    <div className="relative h-full">
      <Header title="Members" subtitle="Organisation Members" />
      <div className="bg-blue-50 flex flex-row mt-20">
        <div className="flex-none w-1/5">
          <h2 className="pl-5 pt-2 pb-5 mt-4 capitalize text-2xl text-gray-400 border-b">
            Members List
          </h2>
        </div>
      </div>
      <div className="w-full">
        <button
          onClick={e => toggleAddMemberForm(e)}
          className="w-auto h-auto bg-purple-500 rounded float-right text-white px-3 py-1 mt-5 mr-20">
          <svg className="w-6 h-6 inline" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
          Add User
        </button>
      </div>
      {
        members?.data?.content === undefined ? 
          <div className="w-full block text-center text-lg mt-20">No members at the moment</div> : 
          <Members members={members.data.content} toggleEditMemberForm={toggleEditMemberForm} />
      }
      <AddMember showAddMemberClass={showAddMemberClass} toggleAddMemberForm={toggleAddMemberForm} refetchMembers={refetchMembers}/>
      <EditMember showEditMemberClass={showEditMemberClass} toggleEditMemberForm={toggleEditMemberForm} member={currentMember}/>
    </div>
  );
};

const Members = ({ members, toggleEditMemberForm }) => {
  return (
    <div className="w-full p-4">
      <table className="w-11/12 mx-auto mt-16 table divide-y divide-gray-200 bg-white shadow-xl">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-center">Name</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/12 text-center">Email</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Phone</th>
            {/* <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/12 text-center">Role</th> */}
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-center">Status</th>
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/12 text-center">Edit</th>
          </tr>
        </thead>
        <tbody>
          {members.map((member) => (
            <tr key={member.publicId} className="hover:bg-blue-100 cursor-pointer">
              <td className="py-3 text-center">{member.fullName}</td>
              <td className="py-3 text-center">{member.username}</td>
              <td className="py-3 text-center">{member.phoneNumber}</td>
              {/* <td className="py-3 text-center">{member.relation.name}</td> */}
              <td className="py-3 text-center">
                <span className="w-auto bg-green-400 text-white rounded px-3 py-1">
                  { member.enabled ? "Active" : "Disabled" }
                </span>
              </td>
              <td className="pr-10 py-3 text-center">
                <button
                  onClick={e => toggleEditMemberForm(e, member)}
                  className="w-auto h-auto bg-purple-500 rounded float-right text-white p-2">
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                  </svg>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MembersIndex;
