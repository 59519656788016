import { gql } from "@apollo/client";

const ADD_PHONE = gql`
mutation AddPhone($request: number_requests_insert_input!) {
  insert_number_requests_one(object: $request){
      id
  }
}
`;

export default ADD_PHONE