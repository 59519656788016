import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import store from './store'
import { Provider } from 'react-redux'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AuthRouter from "./components/auth/AuthRouter";
import AlertTemplate from "./components/common/ui/AlertTemplate";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";


const alertOptions = {
  position: positions.BOTTOM_CENTER,
  timeout: 4000,
  offset: '50px',
  transition: transitions.SCALE
}

// Create a react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retryDelay: attemptIndex => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AuthRouter>
              <App />
            </AuthRouter>
          </Router>
        </QueryClientProvider>
      </Provider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
