import { Link, withRouter } from "react-router-dom"
import { useEffect, useState } from "react";
import moment from "moment";
import {
  PhoneOutgoingIcon,
  XIcon,
  PauseIcon,
  PlayIcon,
  VolumeOffIcon,
  MicrophoneIcon
} from "@heroicons/react/outline";
import { UserAgent, Inviter, SessionState } from 'sip.js';

import outgoingAudio from "../../../static/audio/audio1.wav";
import disconnectedAudio from "../../../static/audio/audio3.wav";

const Outgoing = (props) => {
  const { history, mediaElement, callAudio, destination, hangupCall, toggleMute, toggleHold, received, muted, onHold } = props;
  const [outgoingSession, setOutgoingSession] = useState(null);
  const [time, setTime] = useState("0 secs");
  const [timer, setTimer] = useState(null);
  const [startTime, setStartTime] = useState(Date.now());
  const callState = received ? "Connected" : "Ringing...";
  const timerVisibleClass = received ? "visible" : "hidden";
  const receivedClass = received ? { decline: "block", pinging: "" } : { decline: "hidden", pinging: "animate-ping" };

  const stopTimer = () => {
    console.log("Stopping timer: ", timer);
    clearInterval(timer);
    setStartTime(null);
  };

  const startTimer = () => {
    const timerInterval = setInterval(() => {
      let siunit = " secs";
      let interval = moment(Date.now()).diff(moment(startTime), 'seconds');
      if (interval > 60) {
        siunit = " mins";
        interval = moment(Date.now()).diff(moment(startTime), 'minutes');
      }
      setTime(String(interval) + siunit);
    }, 1000);
    setTimer(timerInterval);
  };

  function cleanupMedia() {
    if (mediaElement) {
      callAudio.src = "";
      mediaElement.srcObject = null;
      callAudio.pause();
      mediaElement.pause();
    }
  }

  const endCall = (e) => {
    if (e !== null) e.preventDefault();
    callAudio.src = disconnectedAudio;
    callAudio.volume = 1.0;
    callAudio.play();
    setTimeout(() => {
      cleanupMedia();
      stopTimer();
      hangupCall();
      if (history.location.pathname.includes('/p/calls/outgoing')) history.goBack();
    }, 2000);
  };

  useEffect(() => {
    cleanupMedia();
  }, [received]);

  useEffect(() => {
    callAudio.src = outgoingAudio;
    callAudio.volume = 1.0;
    callAudio.play();
    setStartTime(Date.now());
    console.log("New Start time: ", startTime);      
    // countdouwn seconds since call started
    startTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <span className="flex h-96 w-96 z-0">
        <span className={"relative inline-flex h-full w-full rounded-full bg-blue-400 opacity-75 " + receivedClass.pinging}></span>

      </span>
      <div className="flex flex-row mt-10">
        <PhoneOutgoingIcon className="h-4 w-4 text-gray-300 pr-1 mt-1" />{" "}
        <span className="text-gray-500 text-lg">Outgoing call</span>
      </div>
      <h1 className="text-4xl">{destination}</h1>
      <h2 className="text-xl">{callState}</h2>
      <h2 className={"text-2xl " + timerVisibleClass}>{time}</h2>
      <div className="flex flex-row gap-10 p-5 z-50 mt-8">
        <Link
          to="#"
          className={"w-12 h-12 flex-none rounded-full bg-purple-500 mt-4 hover:pointer-cursor hover:bg-purple-800 " + receivedClass.decline}
          onClick={e => toggleHold(e, outgoingSession)}>
          {onHold ? <PlayIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" /> : <PauseIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" />}
        </Link>
        <Link
          to="#"
          className="w-18 h-18 flex-none rounded-full bg-red-500 p-5 hover:pointer-cursor hover:bg-red-800"
          onClick={e => endCall(e)}>
          <XIcon className="h-10 w-10 text-white" />
        </Link>
        <Link
          to="#"
          className={"w-12 h-12 flex-none rounded-full bg-pink-500 mt-4 hover:pointer-cursor hover:bg-pink-800 " + receivedClass.decline}
          onClick={e => toggleMute(e, outgoingSession)}>
          {muted ? <MicrophoneIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" /> : <VolumeOffIcon className="h-5 w-5 text-white mt-3.5 ml-3.5" />}
        </Link>
      </div>
    </div>
  );
};

export default withRouter(Outgoing);
