import { BASE_URL, API_VERSION, AT_PORT } from '../utils/config';
import qs from 'qs';
import axios from 'axios';

function login({ queryKey }){
  const [_, body] = queryKey;
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: BASE_URL + AT_PORT + API_VERSION + '/uaa/oauth/authorization', 
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', 
        'Authorization': 'Basic c3lob3Mtd2ViLXVpOmJDYy1fP1d2NWcldjhaaz91WG0rVlJSNjl2LUZiS3RLUXF3eUBTZnVBM3FuZW1KRWZ6Rkczd0E3ZDN2NUM5WCNVS0ozQFJkYSZhV2VQOHVyUjREQGYyQktOREU4OHQmdEptUjduI0BIU0cjdFM1YnAmOUVDeV93XnFFUlpmU0ZLQGU/NFNlYkMmI2duWFEmZyNDLVI9eUY0WXNTWExSSlpMVXloUnZQWSVGbW15dUgzd2c/MlUkZCM2P3R4TGUyTEc0ViFjOCFDYkdDOU1RelVXZlU1dXdxXmFaeVhrQSpfakorZ0tedUF1N3YjQj9SeGNrZEp3PWohUVdeX0EyQio='
      },
      data: qs.stringify(body.payload),
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default login;
