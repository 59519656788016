import { BASE_URL, API_VERSION, AUTH_TOKEN, AT_PORT } from '../utils/config';
import axios from 'axios';

function updateRole(payload){
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: BASE_URL + AT_PORT + API_VERSION + '/actors', 
      headers: {
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${AUTH_TOKEN}`
      },
      data: payload,
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
}

export default updateRole;